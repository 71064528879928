import React from "react";
import vipImage from "assets/images/vip.png";

const PlanTab = ({ selectPlan, selectedPlan }) => {
  
  const list = (isGold) => {
    return (
      <ul>
        <li>Unlimited Al scoring for all question types.</li>
        <li>Full access to the exam questions.</li>
        <li>Access to latest templates and prediction files</li>
        <li>Unlimited sectional mock tests with scoring.</li>
        <li>Unlimited full mock tests with scoring.</li>
        <li>Unlimited comprehensive mock tests with scoring.</li>
        {isGold !== "GOLD" && (
          <>
            <li className="cross">
              <i className="fa fa fa-close" style={{ fontSize: "18px" }}></i>
            </li>
            <li className="cross">
              <i className="fa fa fa-close" style={{ fontSize: "18px" }}></i>
            </li>
            <li className="cross">
              <i className="fa fa fa-close" style={{ fontSize: "18px" }}></i>
            </li>
          </>
        )}
        {isGold === "GOLD" && (
          <>
            <li className="vip">Access to reading explanation videos. <img src={vipImage} /></li>
            <li className="vip">Full access to premium strategy videos. <img src={vipImage} /></li>
            <li className="vip">Full access to the detailed video course. <img src={vipImage} /></li>
          </>
        )}
      </ul>
    )
  };


  const planCard = (planType) => {
    return (
      <div
        className={`col-md-5 adminPlan ${planType === selectedPlan ? "adminGPlan" : "adminSPlan"}`}
        onClick={() => selectPlan(planType)}
      >
        <div className="adminPlanInr shadow-lg">
          <div className="adPlanHead">
            <div className="checkedPlan"><img src="/images/check-icon.png" /></div>
            {planType === "GOLD" && <div className="best-seller-tag">Best Seller</div>}
            <h2>{planType}</h2>
            <span className={planType === "GOLD" ? "planCrownGold" : "planCrown"}></span>
          </div>
          <div className="adPlanBody">
            {list(planType)}
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      {planCard("SILVER")}
      {planCard("GOLD")}
    </>
  );
};

export default PlanTab;
