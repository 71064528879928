import React from "react";
import { Link } from "react-router-dom";

export default function UpcomingClasses(props) {
	const { classes } = props;

	return (
		<div className="upcomingClassBox d-flex flex-column">
			<div className="classBox">
				<h4 className="m-0">Upcoming Classes</h4>
				<ul className="m-0 class-list">
					{classes.map((item, i) => {
						return (
							<li key={i}>
								<a href={item.link} target="_blank" data-toggle="tooltip" data-placement="left" title={item.title}>{item.title}</a>
								<span>{item.time.split(" ")[1]}</span>
							</li>
						)
					})}
					
					<li className="show-more mt-3">
						{classes.length > 0 ? (
							<a href="/admin/session">Show more...</a>
						) : (
							<span>No Upcoming Classes</span>
						)}
					</li>
				</ul>
			</div>

			<Link to="/admin/plans" className="anotherPlan">Upgrade Plan</Link>
		</div>
	);
}