import React, { useState } from "react";

export default function SidebarClose(props) {


	const closeSidebar = () => {
		document.body.classList.add('leftSidebarClosed');
	}

	const openSidebar = () => {
		document.body.classList.remove('leftSidebarClosed');
	}

	const openCloseSidebar = () => {
		document.body.classList.toggle('leftSidebarClosed');
	}

	const openCloseSidebarMobile = () => {
		// document.body.classList.remove('leftSidebarClosed');
		if (document.body.classList.contains('leftSidebarClosed')) {
			document.body.classList.remove('openSidebar');
		} else {
			document.body.classList.add('openSidebar');
		}
	}

	return (
		<div className="sidebar-wrapper">
			<span className="toggleIcon closeSidebar align-self-end mb-3 pr-3" onClick={() => openCloseSidebar()}>
				<img src="/images/new_dash/toggleIcon.svg" alt="collapse icon" />
			</span>

			<span className="toggleIcon openSidebarMobile align-self-end pr-3" onClick={() => openCloseSidebarMobile()}>
				<img src="/images/new_dash/hamburger.png" alt="collapse icon" />
			</span>

			{/*<span className="openSidebar align-self-end mb-3 pr-3" onClick={() => OpenSidebar()}>
				<img src="/images/new_dash/toggleIcon.svg" />
			</span>*/}
		</div>
	);
}
