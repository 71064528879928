import React from "react";
import Select from "react-select";
import { Button } from "reactstrap";
import { timeZonesNames } from "@vvo/tzdb";
import useInput from "../../hooks/useInput";
import API from "api";
import { store } from "store/index";
import { setAlert } from "action";

const timezones = timeZonesNames.map((timezone) => ({
  label: timezone,
  value: timezone,
}));

timezones.unshift({
  label: "Auto detect",
  value: "Auto detect",
});

const TimezoneForm = ({ toggle }) => {
  const { value, onChangeHandler, hasError, onBlurHandler, isValid } = useInput(
    (val) => !!val
  );

  const isAuto = value === "Auto detect";
  let tz;
  if (isAuto) {
    tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
  }

  const onSaveHandler = async () => {
    if (!isValid) {
      onBlurHandler(true);
      return;
    }

    const data =
      value === "Auto detect" ? { timezone: tz } : { timezone: value };

    let response = await API({
      method: "POST",
      url: "setTimezone",
      data,
    });

    if (response.status !== 200) {
      store.dispatch(
        setAlert({
          flag: true,
          type: 2,
          message:
            "Something went wrong. Please check if you are selecting a timezone.",
        })
      );
      return;
    }

    store.dispatch(
      setAlert({ flag: true, type: 1, message: "Timezone saved" })
    );
    toggle(true);
  };

  return (
    <div className="text-center">
      <h6 className="mb-3">Please select your timezone</h6>
      <Select
        className="basic-single"
        classNamePrefix="select"
        placeholder="Search Timezone"
        value={{ label: value, value }}
        isSearchable={true}
        name="timezone"
        options={timezones}
        menuPortalTarget={document.body}
        styles={{
          menuPortal: (base) => ({
            ...base,
            zIndex: 9999,
          }),
        }}
        onChange={(val) => onChangeHandler(val.value)}
        onBlur={onBlurHandler.bind(this, true)}
      />
      {isAuto && <div className="text-start">{tz}</div>}
      {hasError && (
        <div className="text-start text-danger">Please select a timezone</div>
      )}
      <Button className="btn-round mt-3" color="info" onClick={onSaveHandler}>
        Save
      </Button>
    </div>
  );
};

export default TimezoneForm;
