import React, { useState } from 'react';
// import PhoneInput from 'react-phone-input-2';
// import 'react-phone-input-2/lib/material.css';

import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import DialCodes from 'DialCodes.json';

export default function PhoneInputField(props) {
	const [dropdownOpen, setDropdownOpen] = useState(false);
	const [countries, setCountries] = useState(DialCodes.countries);
	const [selectedCountry, setSelectedCountry] = useState(null);
	const [searchString, setSearchString] = useState("");

	const inputRefClick = React.useRef(null);
	const inputRefFocus = React.useRef(null);

	const toggle = () => setDropdownOpen((prevState) => !prevState);


	const filterData = (event) => {
		let filtered = [];
		if (event.target.value !== "") {
			const search = event.target.value.trim().toLowerCase();
			DialCodes.countries.map((country) => {
				if (country.name.toLowerCase().startsWith(search)) {
					filtered.push(country);
				}
			});
		} else {
			filtered = DialCodes.countries;
		}
		setCountries(filtered);
	}

	const countrySelectHandler = (country) => {
		setSelectedCountry(country);
		inputRefFocus.current.focus();
	}

	const handlePhoneNumber = (event) => {
		let phone_number = "";
		if (event.target.value !== "") {
			phone_number = `${selectedCountry.code}${event.target.value}`;
		}
		props.changeEvent(phone_number);
	}

	const dialCode = () => {
		return (
			<span style={{
					fontSize: "14px",
					color: "#9d9e9d"
				}}
			>Dial Code</span>
		);
	}

	const handleClick = () => {
		if (selectedCountry === null) {
			inputRefClick.current.context.toggle();
		}
	};

	return (
		<div className={`d-flex ${props.addClass}`}>
			<Dropdown isOpen={dropdownOpen} toggle={toggle} className="phone-dropdown">
				<DropdownToggle caret ref={inputRefClick}>{selectedCountry === null ? dialCode() : selectedCountry.code}</DropdownToggle>
				<DropdownMenu>
					<input
						type="text"
						onKeyUp={filterData}
						className=""
						placeholder="Search Country"
					/>
					<div className="items-container">
						{countries.map((country, i) => {
							return (
								<DropdownItem
									key={i}
									onClick={() => countrySelectHandler(country)}
								>
									<span className="country-code">{country.code}</span><span>{country.name}</span>
								</DropdownItem>
							)
						})}
					</div>
				</DropdownMenu>
			</Dropdown>
			<input
				type="phone"
				name="phone"
				onChange={handlePhoneNumber}
				placeholder="Phone number"
				className="phone-field"
				autoComplete="off"
				onClick={handleClick}
				ref={inputRefFocus}
				style={{
					padding: "0 10px"
				}}
			/>
		</div>
	);
	
	// return (
	// 	<PhoneInput
	// 		name="phone"
	// 		country={DefaultCountry}
	// 		preferredCountries={PreferredCountries}
	// 		value={props.phone}
	// 		enableSearch={true}
	// 		onChange={props.handlePhoneChange}
	// 		className={props.addClass}
	// 	/>
	// );
}