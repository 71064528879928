import React from 'react';
import { Link,Redirect} from 'react-router-dom';
import Header from '../components/Frontend/Header';
import Footer from '../components/Frontend/Footer';
import FameWallPte from '../components/Frontend/FameWallPte';
import Testimonial from '../components/Frontend/Testimonial';
import TestimonialVid from '../components/Frontend/VideoPTETestimonial';
import SimpleReactValidator from 'simple-react-validator';
import {store} from 'store/index';
import { setAlert} from 'action/index.js';
import API from "api.js";
import { Helmet } from "react-helmet";
import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';
class Pte extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        name: '',
        email: '',
        phone:'',
        course:'PTE On-Campus',
        lang:'Hindi',
        query:'',
        flag:false,
        loginLock:false,
        readMoreFlag:false
      };
      this.validator = new SimpleReactValidator();
    }
    componentDidMount() {
        ReactGA.initialize('UA-157984080-1');
        ReactGA.pageview(window.location.pathname + window.location.search);
        ReactPixel.init('2474090032742391');
        ReactPixel.init('197333187452630');
        ReactPixel.pageView();
    }
    submitForm = (e) => {
      e.preventDefault();
      this.hitApi();
    }

    hitApi = async() => {
      if (this.state.loginLock) {
        return ;
      }
      if (!this.validator.allValid()) {
        this.validator.showMessages();
        this.forceUpdate();
        return ;
      }
      this.setState({loginLock:true});

      var bodyFormData = new FormData();
      bodyFormData.set('email', this.state.email);
      bodyFormData.set('name',this.state.name);
      bodyFormData.set('phone',this.state.phone);
      bodyFormData.set('message','Lang: '+this.state.lang+'<br/>course: '+this.state.course+'<br/>Query: '+this.state.query);
      bodyFormData.set('data', 'email: '+ this.state.email+',name: '+this.state.name+',phone: '+this.state.phone+',lang: '+this.state.lang+',course: '+this.state.course+',query: '+this.state.query);
      bodyFormData.set('page', "PTE coaching Sydney");
      let res = null;
      try{
        res = await API({
          method: 'POST',
          url: 'sendOnlinePte',
          data: bodyFormData
        })
      }catch(error) {


      };
      console.log(res)
      if (res?.status === 200) {
        store.dispatch(setAlert({'flag':true,'type':1,'message':res.data.message}));
        this.setState({name:'',email:'',phone:'',course:'PTE On-Campus',query:'',loginLock:false,flag:true})
      }else{
        this.setState({loginLock:false});
      }


  }

  changeReadMore = () =>{
    this.setState({readMoreFlag:!this.state.readMoreFlag})
  }

  changeInput = (e) => {
    let { name, value } = e.target;
    this.setState({ [name] : value });
  }
 render(){
    if (this.state.flag) {
      return (<Redirect to="/thank-you" />);
    }
    return (
    <React.Fragment>
    <Helmet>
        <title>PTE Coaching Classes in Sydney | Language Academy</title>
        <meta name="twitter:title" content="PTE Coaching Classes in Sydney - Language Academy" />
      <meta property="og:title" content="PTE Coaching Classes in Sydney - Language Academy" />
      <meta name="description" content="Join Language Academy's PTE coaching in Sydney for top-notch PTE training. Achieve your goals with comprehensive practice materials and expert instruction." />

        <meta name="twitter:description" content="Are you searching for the best PTE coaching classes in Parramatta that can help you achieve sure-shot success in your exam? Language Academy, the top PTE training institute in Sydney, provides both in-class and online coaching to help the students to improve their scores in the Pearson Test Of English. To get demo classes from the leading PTE Coaching centre, contact at +61 426230061." />
        <meta property="og:description" content="Are you searching for the best PTE coaching classes in Parramatta that can help you achieve sure-shot success in your exam? Language Academy, the top PTE training institute in Sydney, provides both in-class and online coaching to help the students to improve their scores in the Pearson Test Of English. To get demo classes from the leading PTE Coaching centre, contact at +61 426230061." />
    </Helmet>
    <Header />
    <div className="innerPage">
      <section className="bannerOuter">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 text-center">
              <h1>Achieve your Australian Dream with the Best PTE Coaching Classes in Sydney</h1>
              <span>To hone your English Linguistic Skills, join the top PTE Training Institute, and get 1:1 expert guidance.</span><br/>
              <Link to="/contact" className="themeBtn mt-5">Contact Us</Link>
            </div>
          </div>
        </div>
      </section>
    <section className="whatispte">
      <div className="container">
        <div className="row">
          <div className="col-sm-12">
            <h2 className="title">PTE <span>Training in Sydney</span> with Language Academy</h2>
            <p>Sydney has many English Training institutes with top facilities. We are glad that our coaching Language Academy is one such renowned PTE Coaching in Sydney. With our spacious, fully-facilitated, and individual labs, learning becomes more comfortable. Our experts give 1:1 guidance to each student, which enables overall improvement in each particular section.</p>
            <p>With the best study material and teaching instructors, all of our students get help in focusing on their weak areas and improve. Strategies devised individually for every student helps them in achieving the score they want. PTE and other language exams are challenging, but with the right guidance and personalized attention, any student can ace them. We have the highest success rate in PTE Training in Sydney across all of Australia, and we are very proud of it.</p>
            {!this.state.readMoreFlag && <p onClick={(e)=>{this.changeReadMore()}} className="readmore_text" >Read More</p>}
            {this.state.readMoreFlag && <React.Fragment><h5>Sydney is the Second-best Place in the World to Study!</h5>
                        <p>English Skills are the primary requirement to thrive in an English-speaking country like Australia. Language Academy is a committed and dedicated PTE Coaching in Sydney and recognized among the PTE Coaching Classes in Sydney that help to achieve the big dream to get the Australian PR.</p>
                        <p>Sydney is among the top choices of all kinds of local and migrating students. This city gives anyone a mixed feeling of an exciting and carefree lifestyle. The beautiful sand beaches situated just a few kilometres far along the coastline of the intensely stirring city are peaceful to spend a weekend. For students moving from elsewhere, the city has a lot to make them fond of it.</p>

                        <h5>The PTE Classes in Sydney CBD</h5>
                        <p>Whether you are planning on</p>
                        <ul className="checklist">
                          <li>Migrating to Sydney, Australia</li>
                          <li>Studying in Sydney, Australia</li>
                          <li>Working in Sydney Australia</li>
                        </ul>
                        <p>PTE assessment rewards the learners of English who are not native English speakers but speakers of other languages. It has two types – General and Academic that both help people to prove their English proficiency as non-native speakers and claim candidacy for permanent residency of Australia.</p>
                        <p>PTE Score is accepted by all Universities, helping students willing to continue their higher studies in Australia to move easily. It is also taken by major professional bodies and visa application approval authorities, making it easier to get a PR.</p></React.Fragment>}
                        {this.state.readMoreFlag && <p onClick={(e)=>{this.changeReadMore()}} className="readmore_text" >Read Less</p>}
          </div>
        </div>
      </div>
    </section>
<section className="whyLove">
  <div className="container">
    <div className="row">
      <div className="col-sm-12 text-center">
        <h2 className="title">Why choose <span>Language Academy PTE Coaching Classes Training</span></h2>
        <div className="row whyloveCntnt">
          <div className="col-sm-4">
            <img src="images/experienced-trainer.png" alt="experienced-trainer" />
            <h3>Experienced Trainer</h3>
            <p>Our Pearson certified faculties help you to use their expertise in improving your reading, writing, listening, and speaking sections for Online PTE.</p>
          </div>
          <div className="col-sm-4">
            <img src="images/star-ribben.png" alt="star-ribben" />
            <h3>Highest Success Rate</h3>
            <p>We continuously involve personally with every student, which enables them to unleash the potential in them and achieve the highest scores possible.</p>
          </div>
          <div className="col-sm-4">
            <img src="images/book.png" alt="book" />
            <h3>Free Books</h3>
            <p>Free Templates Every student at the Language Academy gets proven templates to study and understand the best way to write an essay and leave the best impression.</p>
          </div>
          <div className="col-sm-4">
            <img src="images/settings.png" alt="settings" />
            <h3>Latest Resources</h3>
            <p>Students can easily access the study material and take practice tests through our mobile app. Just in a few taps, they can get started.</p>
          </div>
          <div className="col-sm-4">
            <img src="images/support.png" alt="support" />
            <h3>Online Support</h3>
            <p>During the entire class duration, our experts can be reached out to seek any help. They will answer all your questions and help you anywhere you stuck.</p>
          </div>
          <div className="col-sm-4">
            <img src="images/support.png" alt="support" />
            <h3>3500 + Questions to Practice</h3>
            <p>We have curated several previous question papers to help our students in practicing the common questions that are asked frequently.</p>
          </div>
          <div className="col-sm-4">
            <img src="images/support.png" alt="support" />
            <h3>Discussion Sessions</h3>
            <p>Our experts note the weaknesses and strengths of students during the discussion sessions to devise personalized study plans for them.</p>
          </div>
          <div className="col-sm-4">
            <img src="images/support.png" alt="support" />
            <h3>Affordable Fees</h3>
            <p>Our costs are pocket-friendly so the students can easily pay off their fees and improve their score.</p>
          </div>
          <div className="col-sm-4">
            <img src="images/support.png" alt="support" />
            <h3>Real Time Test Environment</h3>
            <p>Our mock tests provide the students with the actual experience of taking tests. Students can test their reading, listening, and writing skills.</p>
          </div>
        </div>
        <Link to="/auth/register"><button className="themeBtn">Register for a FREE Trial Session for PTE</button></Link>
      </div>
    </div>
  </div>
</section>
<TestimonialVid title="PTE Success Stories" />

<FameWallPte />
<section className="WhyLove">
  <div className="container">
    <div className="row whyloveCntnt text-center">
      <div className="col-sm-4">
        <img src="images/support.png" alt="support" />
        <h3>10K+</h3>
        <p>Students Taught</p>
      </div>
      <div className="col-sm-4">
        <img src="images/support.png" alt="support" />
        <h3>5000+</h3>
        <p>Students Scored 79+</p>
      </div>
      <div className="col-sm-4">
        <img src="images/support.png" alt="support" />
        <h3>100+</h3>
        <p>Online Success Stories</p>
      </div>
      <div className="col-sm-4">
        <img src="images/support.png" alt="support" />
        <h3>8K+</h3>
        <p>Total Success Stories</p>
      </div>
    </div>
  </div>
</section>
<Testimonial />
<section className="mapOuter">
  <div className="container-fluid p-0">
    <div className="row">
      <div className="col-sm-12">
          <iframe style={{width: '100%', height: '500px'}} src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=Suite%20502%20Level%205/630%20George%20St,%20Sydney%20NSW%202000,%20Australi+(Language%20Academy%20Sydney%20-%20PTE,%20IELTS%20&amp;%20NAATI%20CCL%20Coaching%20Classes)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed" frameBorder="0" allowFullScreen="" aria-hidden="false" tabIndex="0"></iframe>
      </div>
    </div>
  </div>
</section>
<section className="tutorialVd">
  <div className="container">
    <div className="row">
      <div className="col-sm-12 text-center">
        <h2 className="title">PTE <span>Tutorial Videos</span></h2>
        <div className="videosCntnt">
          <div className="videoBlk">
            <div className="videoOuter">
              <iframe width="360" height="203" src="https://www.youtube.com/embed/KrFkLVq-sgw" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            </div>
          </div>
          <div className="videoBlk">
            <div className="videoOuter">
              <iframe width="360" height="203" src="https://www.youtube.com/embed/cDaGxk8fYpg" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            </div>
          </div>
          <div className="videoBlk">
            <div className="videoOuter">
              <iframe width="360" height="203" src="https://www.youtube.com/embed/EERIFvmG8AI" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

    	<Footer />
      </div>
    </React.Fragment>);
    }
}

export default Pte;
