import React from "react";
import ReactHtmlParser from "react-html-parser";

const InfoCard = ({ plan, discount, prorationSubtotal, paymentMethod }) => {
  const isSilver = plan.is_gold === 0 && plan.is_video === 0;
  const planTitle = isSilver ? "SILVER" : "GOLD";
  let price;
  let currency = "AUD";
  if (plan?.currency !== "AUD") {
    currency = ReactHtmlParser(plan?.currency);
    price = `${currency} ${plan?.price}`;
  } else {
    price = `${plan?.currency} ${plan?.price}`;
  }

  return (
    <div className={"priceBlock " + (isSilver && "silver")}>
      <span>{planTitle}</span>
      {discount && prorationSubtotal && prorationSubtotal.subtotal ? (
        <>
          <div className="d-flex align-items-baseline">
            <div className="mr-1">
              <h2>
                Price - {currency} {prorationSubtotal.subtotal / 100}
              </h2>{" "}
            </div>
            <div>
              <span className="fs-4 mr-3">
                <small>
                  <s>{price && price}</s>
                </small>
              </span>
            </div>
          </div>
          <span className="fs-6">
            This discount is extended as a price adjustment facilitating the
            transition from your Silver package to the Gold package.
          </span>
        </>
      ) : (
        <>
          {paymentMethod === "CARD" ? (
            <h2>Price - {price && price}</h2>
          ) : (
            <h2>UPI Packages</h2>
          )}
        </>
      )}
      <p className="planDuration">
        {/* For {plan?.duration} month [recurring payments] */}

        {/* For CCAvenue Only */}
        {/* {paymentMethod === "CARD" ? "[Auto Renewed]" : "[One-time Purchase]"} */}
        
        {/* For RazorPay */}
        [Auto Renewed]
      </p>
    </div>
  );
};

export default InfoCard;
