import React from 'react';
import { Link,Redirect} from 'react-router-dom';
import Header from '../components/Frontend/Header';
import Footer from '../components/Frontend/Footer';
import FameWallPte from '../components/Frontend/FameWallPte';
import Testimonial from '../components/Frontend/Testimonial';
import VideoTuts from '../components/Frontend/VideoTuts';
import WhyLove from '../components/Frontend/WhyLove';
import SimpleReactValidator from 'simple-react-validator';
import {store} from 'store/index';
import { setAlert} from 'action/index.js';
import API from "api.js";
import { Helmet } from "react-helmet";
import ReactGA from 'react-ga';
import FameWall from '../components/Frontend/FameWallIelts';
import ReactPixel from 'react-facebook-pixel';
import TestimonialVid from '../components/Frontend/VideoIeltsTestimonial';
class Pte extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        name: '',
        email: '',
        phone:'',
        course:'IELTS Online',
        lang:'Hindi',
        query:'',
        flag:false,
        loginLock:false,
        readMoreFlag:false
      };
      this.validator = new SimpleReactValidator();
    }
    componentDidMount() {
        ReactGA.initialize('UA-157984080-1');
        ReactGA.pageview(window.location.pathname + window.location.search);
        ReactPixel.init('2474090032742391');
        ReactPixel.init('197333187452630');
        ReactPixel.pageView();
    }
    submitForm = (e) => {
      e.preventDefault();
      this.hitApi();
    }

    hitApi = async() => {
      if (this.state.loginLock) {
        return ;
      }
      if (!this.validator.allValid()) {
        this.validator.showMessages();
        this.forceUpdate();
        return ;
      }
      this.setState({loginLock:true});

      var bodyFormData = new FormData();
      bodyFormData.set('email', this.state.email);
      bodyFormData.set('name',this.state.name);
      bodyFormData.set('phone',this.state.phone);
      bodyFormData.set('message','<b>course:</b> '+this.state.course+'<br/><b>Query:</b> '+this.state.query);
      bodyFormData.set('data', 'email: '+ this.state.email+',name: '+this.state.name+',phone: '+this.state.phone+',course: '+this.state.course+',query: '+this.state.query);
      bodyFormData.set('page', "IELTS coaching parramatta");
      let res = null;
      try{
        res = await API({
          method: 'POST',
          url: 'sendOnlinePte',
          data: bodyFormData
        })
      }catch(error) {


      };
      console.log(res)
      if (res?.status === 200) {
        store.dispatch(setAlert({'flag':true,'type':1,'message':res.data.message}));
        this.setState({name:'',email:'',phone:'',course:'PTE On-Campus',query:'',loginLock:false,flag:true})
      }else{
        this.setState({loginLock:false});
      }


  }

  changeReadMore = () =>{
    this.setState({readMoreFlag:!this.state.readMoreFlag})
  }

  changeInput = (e) => {
    let { name, value } = e.target;
    this.setState({ [name] : value });
  }
 render(){
  if (this.state.flag) {
    return (<Redirect to="/thank-you" />);
  }
    return (
    <React.Fragment>
    <Helmet>
        <title>IELTS Coaching  Classes in Parramatta | Language Academy</title>
        <meta name="twitter:title" content="IELTS Classes in Parramatta - Language Academy" />
        <meta property="og:title" content="IELTS Classes in Parramatta - Language Academy" />
        <meta name="description" content="Attend IELTS coaching classes in Parramatta with Language Academy. Get personalized instruction and comprehensive practice to excel in your IELTS exam." />

        <meta name="twitter:description" content="Language Academy is a leading training Institute for IELTS Courses Online. Book your IELTS demo class with Language Academy now. Contact us at +61 426230061." />
        <meta property="og:description" content="Language Academy is a leading training Institute for IELTS Courses Online. Book your IELTS demo class with Language Academy now. Contact us at +61 426230061." />
    </Helmet>
    <div className="innerPage pteOnline ieltsClasses">
    <Header />
      <section className="bannerOuter">
  <div className="container">
    <div className="row">
      <div className="col-sm-12 text-center">
        <h1>IELTS Training Centre in Parramatta</h1>
        <Link to="/contact" className="themeBtn">Contact us</Link>
      </div>
    </div>
  </div>
</section>
<section className="ieltsTraining">
  <div className="container">
    <div className="row">
      <div className="col-sm-12 ">
        <div className="text-center">
        <h2 className="title">IELTS training in <span>Parramatta with Language Academy</span></h2>
        </div>
        <p>At Language Academy, our IELTS training course is proven to be quite effective in improving our students’ English acumen. Expert faculties with finest subject matter expertise and best teaching methodology create the personalized curriculum for each student.</p>
        <p>Students can quickly start peaking the scores as our stringent curriculum includes a lot of practice, assessment and feedback. We get immensely large number of positive reviews for our impeccable IELTS training in Parramatta.</p>
        <p>Learners attending our IELTS training course, praise us a lot and even share with us how much do they enjoy taking part in all the dimensions of English activities. They told us that they even excel at times in different sorts of personal, professional and cultural activities.</p>
        {!this.state.readMoreFlag && <p onClick={(e)=>{this.changeReadMore()}} className="readmore_text" >Read More</p>}
        {this.state.readMoreFlag && <React.Fragment><h5>IELTS coaching in Parramatta is the best to Start in life</h5>
        <p>Language Academy’s Parramatta campus is located at the center of the city. The public transport facilities can be easily accessed in just a few minutes.</p>
        <p>We are at 22 Horwood Place in Suite 22, right next to the George Street. The building has a lot of food and beverage outlets nearby. We have a secured parking for all the students and experts right in the basement of our building. We’re a short walk from the bus stop at the Parramatta interchange. Even Parramatta station is just a short walk from the campus.</p>

        <h5>The IELTS classes in Parramatta</h5>
        <p>IELTS assesses and gives an internationally recognized certificate to the people successfully qualifying it. Like other countries, Australian government also accepts IELTS valid score as a prerequisite for migrating to Australia.</p>
        <p>IELTS exam includes four sections: Reading, Writing, Listening and Speaking.</p>
        <p>IELTS classes in Parramatta like Language Academy offer both online and offline courses. The IELTS exam score ranges between 0 and 9 and the exam is done in two categories General and Academic.</p>
        <p>Students migrating to Parramatta, Australia to attend a course can opt for IELTS Academic. Whereas, anyone else who is migrating for all other non-educational purposes can take IELTS General.</p></React.Fragment>}
        {this.state.readMoreFlag && <p onClick={(e)=>{this.changeReadMore()}} className="readmore_text">Read Less</p>}
      </div>
    </div>
  </div>
</section>
<section className="practicePortal">
  <div className="container">
    <div className="row">
      <div className="col-sm-12 text-center">
        <h2 className="title">Why choose <span>Language Academy IELTS Training</span></h2>
        <div className="row whyloveCntnt">
          <div className="col-sm-4">
            <img src="images/experienced-trainer.png" alt="experienced-trainer" />
            <h3>Experienced Trainer</h3>
            <p>They are adept at both Linguistics skills and imparting the educational lectures online.</p>
          </div>
          <div className="col-sm-4">
            <img src="images/star-ribben.png" alt="star-ribben" />
            <h3>Highest Success Rate</h3>
            <p>With detailed and well-planned online learning modules, we are able to give the best training to our online students, and they are constantly achieving 8+ band scores.</p>
          </div>
          <div className="col-sm-4">
            <img src="images/book.png" alt="book" />
            <h3>Free Intensive Material</h3>
            <p>Our templates make it easier for any online student to self-learn quickly from the framework and follow them to achieve the best scores.</p>
          </div>
          <div className="col-sm-4">
            <img src="images/settings.png" alt="settings" />
            <h3>Latest Resources</h3>
            <p>Our students get access to our online learning resources.</p>
          </div>
          <div className="col-sm-4">
            <img src="images/support.png" alt="support" />
            <h3>Online Support</h3>
            <p>Our experts are always for any online student. You can reach out to any trainer and ask questions or seek help in solving any doubts that you have.</p>
          </div>
          <div className="col-sm-4">
            <img src="images/support.png" alt="support" />
            <h3>Past Exam questions to Practice</h3>
            <p>We have a repository of past exam questions with answers to practice from that can help any student to quickly practice the previous year’s questions.</p>
          </div>
          <div className="col-sm-4">
            <img src="images/support.png" alt="support" />
            <h3>Discussion Sessions</h3>
            <p>Our faculties carefully assess the level of the students during the online discussion sessions and suggest the personalized study plan.</p>
          </div>
          <div className="col-sm-4">
            <img src="images/support.png" alt="support" />
            <h3>Affordable Fees</h3>
            <p>We have kept the online course fees so less so that every student can easily afford to pay the costs on their own.</p>
          </div>
          <div className="col-sm-4">
            <img src="images/support.png" alt="support" />
            <h3>Real Time Test Environment</h3>
            <p>We assess all our online students from time to time and give them a real-time test environment, so they acclimate to the actual test environment and feel less stressed during the actual test.</p>
          </div>
        </div>
        <Link to="/auth/register"><button className="themeBtn">Register for a FREE Trial Session for IELTS</button></Link>
      </div>
    </div>
  </div>
</section>
<section className="WhyLove">
  <div className="container">
    <div className="row whyloveCntnt text-center">
      <div className="col-sm-4">
        <img src="images/support.png" alt="support" />
        <h3>500+</h3>
        <p>Coaching Preview</p>
      </div>
      <div className="col-sm-4">
        <img src="images/support.png" alt="support" />
        <h3>500+</h3>
        <p>Registered students</p>
      </div>
      <div className="col-sm-4">
        <img src="images/support.png" alt="support" />
        <h3>200+</h3>
        <p>Successful Students</p>
      </div>
    </div>
  </div>
</section>
<section className="getWithUs">
  <div className="container">
    <div className="row">
      <div className="col-sm-12">
        <h2 className="title text-center">What You Will <span>Get With Us</span></h2>
        <div className="row">
          <div className="col-sm-8">
            <div className="ourFeatures">
              <ul className="checklist">
                <li>Unlimited Classes</li>
                <li>Unlimited Writing & Speaking Assessments</li>
                <li>1 to 1 Tutorial with Tutor</li>
                <li>Practice Questions</li>
                <li>Method Lessons</li>
                <li>Overview Classes</li>
                <li>Pre-Test Strategy Sessions</li>
                <li>Post-Test Consultations</li>
              </ul>
            </div>
          </div>
          <div className="col-sm-4 text-center">
            <div className="naatiCoaching">
              <h3>Take a FREE Demo Class of Online IELTS Coaching</h3>
              <form onSubmit={this.submitForm}>
                <div className="fieldOuter">
                  <input
                    type="text"
                    name="name"
                    placeholder="Name*"
                    value={this.state.name}
                    onChange={(e) => { this.changeInput(e)} }
                  />
                  {this.validator.message('Name', this.state.name, 'required')}
                </div>
                <div className="fieldOuter">
                  <input
                    type="email"
                    name="email"
                    placeholder="Email*"
                    value={this.state.email}
                    onChange={(e) => { this.changeInput(e)} }
                  />
                  {this.validator.message('Email', this.state.email, 'required')}
                </div>
                <div className="fieldOuter">
                  <input
                    type="text"
                    name="phone"
                    placeholder="Phone*"
                    value={this.state.phone}
                    onChange={(e) => { this.changeInput(e)} }
                  />
                  {this.validator.message('Phone', this.state.phone, 'required')}
                </div>
                <div className="fieldOuter">
                  <select
                    name="course"
                    value={this.state.course}
                    onChange={(e) => { this.changeInput(e)} }>
                                <option value="IELTS Online">IELTS Online</option>
                                <option value="PTE On-Campus">PTE On-Campus</option>
                                <option value="PTE Online">PTE Online</option>
                                <option value="NAATI CCL On-Campus">NAATI CCL On-Campus</option>
                                <option value="IELTS Online">NAATI CCL Online</option>
                                <option value="IELTS On-Campus">IELTS On-Campus</option>
                              </select>
                </div>
                <div className="fieldOuter">
                  <input
                    type="text"
                    name="query"
                    placeholder="Comments/Query"
                    value={this.state.query}
                    onChange={(e) => { this.changeInput(e)} }
                  />
                  {this.validator.message('Query', this.state.query, 'required')}
                </div>
                <div className="fieldOuter">
                  {!this.state.loginLock && <button className="themeBtn" onClick={(e)=>{this.hitApi()}}>Send Message</button>}
                  {this.state.loginLock && <button className="themeBtn" >Wait...</button>}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<Testimonial />
<FameWall />
<TestimonialVid title="What Our Students Say About Us" />
<section className="tutorialVd">
  <div className="container">
    <div className="row">
      <div className="col-sm-12 text-center">
        <h2 className="title">IELTS <span>Tutorial Videos</span></h2>
        <div className="videosCntnt">
          <div className="videoBlk">
            <div className="videoOuter">
              <iframe width="360" height="203" src="https://www.youtube.com/embed/_ZXBmscplck?list=PLfM0GWoT8-NLQ_nsckqcwQtwbV6V4rg_6" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            </div>
          </div>
          <div className="videoBlk">
            <div className="videoOuter">
              <iframe width="360" height="203" src="https://www.youtube.com/embed/Hhu17DFCQtM?list=PLfM0GWoT8-NLQ_nsckqcwQtwbV6V4rg_6" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            </div>
          </div>
          <div className="videoBlk">
            <div className="videoOuter">
              <iframe width="360" height="203" src="https://www.youtube.com/embed/W2QX4_73sGs?list=PLfM0GWoT8-NLQ_nsckqcwQtwbV6V4rg_6" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<section className="mapOuter">
  <div className="container-fluid p-0">
    <div className="row">
      <div className="col-sm-12">
          <iframe style={{width: '100%', height: '500px'}} src="https://maps.google.com/maps?q=Language%20Academy%20parramatta%20&amp;t=&amp;z=13&amp;ie=UTF8&amp;iwloc=&amp;output=embed" frameBorder="0" allowFullScreen="" aria-hidden="false" tabIndex="0"></iframe>
      </div>
    </div>
  </div>
</section>
    <Footer />
      </div>
    </React.Fragment>);
    }
}

export default Pte;
