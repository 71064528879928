/*global chrome*/
import React from 'react';
import { Link} from 'react-router-dom';
import API,{base} from "../../api.js";
import Slider from "react-slick";
class VideoTuts extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            homePage:false,
            list:[]
        }
    }

    componentDidMount() {
        API({
            method: 'GET',
              url: "display/video",
              data:'json',
            }).then(data => {
              this.setState({list:data.data.response.data});
            }).catch(()=>{
                this.setState({lockSubmit:false});
            });
    }
 render(){
    var settings = {
      dots: false,
      infinite: true,
      slidesToShow: 3,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 2,
            infinite: true,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 480,
          settings: {
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };
	return (
		<section className="tutorialVd">
            <div className="container">
                <div className="row">
                    <div className="col-sm-12 text-center">
                        <h2 className="title">Tutorial <span>Videos</span></h2>
                        <div className="videosCntnt">
                            <Slider {...settings} classNameName="testiContent regular02 slider">
                                {this.state.list.map((item,key)=>{
                                    return (<div className="videoBlk" key={key}>
                                <div className="videoOuter">
                                    <iframe src={item.video} />
                                </div>
                                <p>{item.title}</p>
                            </div>);
                                })}
                            
                            </Slider>
                            
                            
                        </div>
                        <Link to={{ pathname: "https://www.youtube.com/channel/UC-yXI8Y7cry-D5uuNGbYbsw" }} target="_blank">
                            <button className="themeBtn">View All Videos</button>
                        </Link>
                    </div>
                </div>
            </div>
        </section>
	);
}
}

export default VideoTuts;