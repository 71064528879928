import React, { useState, useEffect } from 'react';
import {
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
	Button
} from 'reactstrap';
import Speech from 'speak-tts';
import API from 'api.js';

import multiLing from "assets/images/multi-ling.png";

export default function WordDefinitionModal(props) {
	const { isOpen, toggle, word } = props;

	const DEFAULT_DEFINITION = [{
		definition: "Fetching definition(s)..."
	}];
	const DEFINITION_ERROR = [{
		definition: "Unable to fetch definition(s). Please try again later."
	}];
	const [definitions, setDefinitions] = useState(DEFAULT_DEFINITION);
	const [definitionIsLoaded, setLoaded] = useState(false);

	useEffect(() => {
		if (word !== "") {
			API({
				url: "word/definition",
				method: "POST",
				data: { word }
			}).then((res) => {
				if (res.status === 200) {
					const { word_definitions } = res.data.data;
					setLoaded(true);
					setDefinitions(word_definitions);
				} else {
					setDefinitions(DEFINITION_ERROR);
				}
			}).catch((err) => {
				setDefinitions(DEFINITION_ERROR);
			});
		}
	}, []);

	const pronunciation = (word) => {
		const speech = new Speech();
		speech.speak({ text: word });
	}

	return (
		<Modal
			isOpen={isOpen}
			size="lg"
			backdropClassName="shadow-backdrop"
			className="practice-shadowing-modal word-definition-modal"
		>
			<ModalHeader
				className="word-definition-heading"
				tag="div"
			>
				<div className="row">
					<div className="col-md-7">
						<h4 className="mb-2 text-light">
							{word}
							<i
								onClick={() => pronunciation(word)}
								className="fa fa-volume-up"
								aria-hidden="true"
							></i>
						</h4>
					</div>
					<div className="col-md-3">&nbsp;</div>
					<div className="col-md-2">
						<img src={multiLing} alt="multi-languages" />
					</div>
				</div>
			</ModalHeader>
			<ModalBody>
				<div className="text-translation">
					<div className="mb-2 definition-text">
						{definitionIsLoaded ? (
							<>
								{definitions.map((definition, i) => {
									return (<div key={i} className="word-definition">{definition.definition}</div>)
								})}
							</>
						) : (
							<ul>
								{definitions.map((definition, i) => {
									return (<li key={i}>{definition.definition}</li>)
								})}
							</ul>
						)}
					</div>
				</div>
			</ModalBody>
			<ModalFooter>
				<Button
					color="danger"
					className="close-button"
					onClick={() => toggle(false)}
				>Close</Button>
			</ModalFooter>
		</Modal>
	);
}