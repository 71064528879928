import React from 'react';
import Header from '../components/Frontend/Header';
import Footer from '../components/Frontend/Footer';
import FameWall from '../components/Frontend/FameWall';
import Testimonial from '../components/Frontend/Testimonial';
import VideoTuts from '../components/Frontend/VideoTuts';
import WhyLove from '../components/Frontend/WhyLove';
import { Link} from 'react-router-dom';
import { Helmet } from "react-helmet";
import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';
class Home extends React.Component {
    componentDidMount() {
        ReactGA.initialize('UA-157984080-1');
        ReactGA.pageview(window.location.pathname + window.location.search);
        ReactPixel.init('2474090032742391');
        ReactPixel.init('197333187452630');
        ReactPixel.pageView();
    }
 render(){
    return (
    <React.Fragment>
    <Helmet>
        <title>PTE Reading Test Practice Material | Language Academy</title>
        <meta name="description" content="Enhance your reading skills with top-quality PTE reading test practice materials from Language Academy. Access a variety of exercises and tips to excel in your PTE reading test." />
        <meta name="twitter:title" content="PTE Reading Practice Test Material | Best PTE Reading Mock Test" />
        <meta name="og:title" content="PTE Reading Practice Test Material | Best PTE Reading Mock Test" />
        <meta name="twitter:description" content="PTE reading practice test material plays an important role when it comes to improving your scores in the reading module. By regularly attempting online PTE reading mock test sets, you can develop a strategy of your own to tackle all types of questions in the exam efficiently. If you want the latest practice material that can help you prepare well for PTE academic reading section, contact us now!" />
        <meta property="og:description" content="PTE reading practice test material plays an important role when it comes to improving your scores in the reading module. By regularly attempting online PTE reading mock test sets, you can develop a strategy of your own to tackle all types of questions in the exam efficiently. If you want the latest practice material that can help you prepare well for PTE academic reading section, contact us now!" />
      </Helmet>
    <Header />
    <div className="innerPage">
    <section className="bannerOuter">
            <div className="container">
                <div className="row">
                    <div className="col-sm-12 text-center">
                        <h1>Refine your reading skills using the Best PTE Reading Practice Test Material</h1>
                        <span>Practice the latest PTE Reading Mock Test questions regularly and attain your target score</span><br />
                        <a href="javascript:;" className="downArrow"><img src="images/banner-arrow.png" alt="banner-arrow" /></a>
                    </div>
                </div>
            </div>
        </section>
        </div>

      <section className="pteInfo">
         <div className="container">
                <div className="row">
                    <div className="col-sm-12 ">
                  <p className="web-content">
                     Are you searching for the best PTE reading practice material? If yes, you can nimbly make a buying decision with us because we know the winning strategy for PTE online reading test that not only focuses on the reading part but can also give you an idea about the best writing techniques. Today, a few aspirants don't take the PTE academic reading practice test seriously due to overconfidence and low priority level, but this is also crucial like other tests.
                  </p>
                  <p className="web-content">
                    Before moving on to the next step, we should know about the importance of PTE reading practice test.
                  </p>
                  <ul>
                    <li className="web-content">By using the PTE reading mock test, we can easily enhance our vocabulary and make ourselves aware of the words with which we were not familiar earlier.</li>
                    <li className="web-content">An aspirant can get an idea about the content structure from the paragraph mentioned in the PTE reading material.</li>
                    <li className="web-content">Apart from these two benefits, the aspirant can also learn the content writing techniques by reviewing the PTE exam reading practice test.</li>
                    <li className="web-content">One can also learn the usage of different words from PTE academic reading material.</li>
                  </ul>
                  <p className="web-content">After knowing these immense benefits, do you want to avail the best PTE reading material? You can contact us over a call on <a style={{color: 'rgb(81, 203, 206)'}} href="tel:+61426230061" target="blank">+61 426230061</a> and start your PTE reading practice online with us now!</p>
               </div>
            </div>
         </div>
      </section>
    <section id="pte-modules">
         <div className="container">
            <div className="row text-center">
               <div className="col-lg-4 col-sm-4">
                  <Link to="/pte-listening-test-practice-material">
                     <div className="module-wrapper">
                        <div className="module-icon">
                           <img src="images/listening.png" className="listening" />
                        </div>
                        <h3 className="module-title">Listening</h3>
                        <p className="module-description">Start preparing for the listening section using the latest practice material.</p>
                     </div>
                  </Link>
               </div>
               <div className="col-lg-4 col-sm-4">
                  <Link to="/pte-writing-test-practice-material" >
                     <div className="module-wrapper">
                        <div className="module-icon">
                           <img src="images/writing.png" className="writing" />
                        </div>
                        <h3 className="module-title">Writing</h3>
                        <p className="module-description">Sharpen your writing skills with the best PTE writing practice material.</p>
                     </div>
                  </Link>
               </div>
               <div className="col-lg-4 col-sm-4">
                  <Link to="/pte-speaking-test-practice-material">
                     <div className="module-wrapper">
                        <div className="module-icon">
                           <img src="images/speaking.png" className="speaking" />
                        </div>
                        <h3 className="module-title">Speaking</h3>
                        <p className="module-description">Get the guidance from our experts and remove all the hurdles that you face in PTE speaking test module.</p>
                     </div>
                  </Link>
               </div>
            </div>
         </div>
      </section>
    <WhyLove />
    <Testimonial />
    <Footer />
    </React.Fragment>);
    }
}

export default Home;
