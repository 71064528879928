import React,{Component} from "react";

// reactstrap components
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Row,
  Alert,
  Col,Button,
  CardFooter
} from "reactstrap";
import SimpleReactValidator from 'simple-react-validator';
import { setAlert} from 'action/index.js';
import API from "api.js";
import { NavLink } from "react-router-dom";
import { setUser} from 'action/index.js';
// core components
import PanelHeader from "components/PanelHeader/PanelHeader.jsx";
import {store,persistor} from 'store/index';
import { connect } from 'react-redux';
import { thead, tbody } from "variables/general";
import SidebarClose from "components/Sidebar/SidebarClose";

const mapStateToProps = (state) => {
   return {
      user: state.user
   };
};
const mapDispatchToProps = (dispatch) => {
   return {
      setUser: (user) => dispatch(setUser(user))
   };
};
class Mock extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      email:store.getState().user.email,
      otp:'',
      // password:'',
      password:'',
      password_confirmation:'',
      error_bag:null,
      otpflag:false,
    };
    this.validator = new SimpleReactValidator();
  }

  changeInput = (e) => {
    let { name, value } = e.target;
    this.setState({ [name] : value });
  };

  componentDidMount() {

  }

  postSignUp = async(e) => {
    if (!this.validator.allValid()) {
      this.validator.showMessages();
      this.forceUpdate();
      return false;
    }
    this.setState({error_bag:null});
    e.preventDefault();
    var bodyFormData = new FormData();
    bodyFormData.set('otp', this.state.otp);
    // bodyFormData.set('password', this.state.password);
    bodyFormData.set('password', this.state.password);
    bodyFormData.set('password_confirmation', this.state.password_confirmation);
    let res = null;
    try{
      res = await API({
        method: 'POST',
        url: '/changePassword',
        data: bodyFormData
      })
    }catch(error) {
        console.error(error);  
        this.setState({error:{error} });
    };
    
    if (res.status === 200) {
      store.dispatch(setAlert({'flag':true,'type':1,'message':"Password changed successfully!"}));       
    }else if (res.response.status === 409) {
      this.setState({error:res.response });
      let str = '';
      this.setState({error_bag:"OTP is either invalid or expired!"});
    }else if (res.response.status === 422) {
      this.setState({error:res.response });
      let str = '';
      this.setState({error_bag:res.response.data.message});
    }
    
    
  }

  toggle = tab => {
    if(this.state.activeTab !== tab){
      this.setState({activeTab:tab});
    }
  }

  sendOTP = async(e) => {
    
    this.setState({error_bag:null});
    e.preventDefault();
    let cred = {
      email: this.state.email,
    };
    var bodyFormData = new FormData();
    bodyFormData.set('email', this.state.email);
    let res = null;
    try{
      res = await API({
        method: 'POST',
        url: '/requestotp',
        data: bodyFormData
      })
    }catch(error) {

        
    };
    if (res.status === 200) {
      this.setState({otpflag:true,error_bag:"Verification code sent to your Email !"});
    }else if(res.response.status === 422){
      this.setState({error:res.response });
      let str = '';
      
      Object.values(this.state.error.data.errors).forEach((val) => { str += val[0]+'\
        '; })
      this.setState({error_bag:str});

    }
    else if (res.response.status === 402) {
      this.setState({error_bag:"Wrong credentials."});
    }
    
  }
 
  render() {
    return (
      <>
        <div className="content">
          <SidebarClose />
          <Row>
            <Col xs={12}>
              <Card>
                <CardHeader>
                  {this.state.error_bag && <Alert color="warning">{this.state.error_bag}</Alert>}
                  <CardTitle tag="h4">Change Password</CardTitle>
                </CardHeader>
                <CardBody>
                <Row>
                  <Col className="mb-5" md="12">
                    <Button className="themeBtn" onClick={(e) => {this.sendOTP(e)}}>Click to, Request a verification code on your mail!</Button>
                  </Col>
                </Row>
                   <Form>
                    <Row>
                      <Col className="" md="7">
                        <FormGroup>
                          <label>Verification Code</label>
                          <Input
                            defaultValue={this.state.otp}
                            name="otp"
                            placeholder="Verification Code"
                            type="text"
                            onChange={(e) => { this.changeInput(e)} }
                          />
                          {this.validator.message('verification code', this.state.otp, 'required')}
                        </FormGroup>
                        {/* <FormGroup>
                          <label>Old Password</label>
                          <Input
                            defaultValue={this.state.password}
                            name="password"
                            placeholder="Old Password"
                            type="text"
                            onChange={(e) => { this.changeInput(e)} }
                          />
                          {this.validator.message('old password', this.state.password, 'required')}
                        </FormGroup> */}
                      </Col>
                      <Col className="" md="7">
                        <FormGroup>
                          <label>New Password</label>
                          <Input
                            defaultValue={this.state.password}
                            name="password"
                            placeholder="New Password"
                            type="text"
                            onChange={(e) => { this.changeInput(e)} }
                          />
                          {this.validator.message('new password', this.state.password, 'required')}
                        </FormGroup>
                      </Col>
                      <Col className="" md="7">
                        <FormGroup>
                          <label>Confirm Password</label>
                          <Input
                            defaultValue={this.state.password_confirmation}
                            name="password_confirmation"
                            placeholder="Confirm Password"
                            type="text"
                            onChange={(e) => { this.changeInput(e)} }
                          />
                          {this.validator.message('confirm password', this.state.password_confirmation, `required|in:${this.state.password}`, {messages: {in: 'Passwords need to match!'}})}
                        </FormGroup>
                      </Col>
                    </Row>
                    
                    
                   
                    
                  </Form>
                   
                </CardBody>
                <CardFooter>
                  <Button
                    className="btn-round"
                    color="info"
                    onClick={this.postSignUp}
                  >
                    Save
                  </Button>
                </CardFooter>
              </Card>
            </Col>
            
          </Row>
        </div>
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Mock);
