import React from "react";
import { Modal, ModalBody } from "reactstrap";

const ErrorPopup = ({ toggle, message, subMessage, img, country, upi }) => {

  return (
    <Modal isOpen={true} className="paymentFailedPop">
      <ModalBody>
        <span
          onClick={() => toggle()}
          style={{
            color: "#93c13e",
            opacity: "unset",
            position: "absolute",
            top: "4%",
            right: "3%",
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">×</span>
        </span>
        <div className="errorImg">
          {img && img}
        </div>
        {message && (
          <div className="mt-4 text-center">
            <h6 className="popTitle">{message}</h6>
            {subMessage && (
              <div className="popDesc">
                <small>{subMessage}</small>
                
                {country === "IN" &&
                  <div className="row popBtns">
                    <div className="col-12 mb-3">
                      <button
                        type="button"
                        className="retry-button btn btn-primary btn-block btnOutline"
                        onClick={() => { window.location.reload() }}
                      >Retry</button>
                    </div>
                    <div className="col-12">
                      <button
                        type="button"
                        className="upi-button btn btn-block"
                        onClick={upi}
                      >
                        Pay with UPI
                      </button>
                    </div>
                  </div>
                }
              </div>
            )}
          </div>
        )}
      </ModalBody>
    </Modal>
  );
};

export default ErrorPopup;
