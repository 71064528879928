import React from "react";
import { Button, Modal, ModalBody, ModalFooter } from "reactstrap";

const Confirm = ({ status, confirm, setStatus, message }) => {
  return (
    <Modal isOpen={status} size="sm">
      <ModalBody>
        <div>{message}</div>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" className="modalCloseBtn" onClick={confirm}>
          Yes
        </Button>
        <Button color="secondary" className="modalCloseBtn" onClick={() => setStatus(false)}>
          No
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default Confirm;
