import React, { useState, useEffect } from 'react';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

export default function SelectVoiceDropDown({
	voices,
	selected,
	changeAudio
}) {

	const voiceOptions = voices;
	
	const [dropdownOpen, setDropdownOpen] = useState(false);
	const [selectedVoice, setSelectedVoice] = useState(selected);

	const toggle = () => setDropdownOpen((prevState) => !prevState);

	const onSelect = (item) => {
		setSelectedVoice(item.label);
		changeAudio(item);
	}

	return (
		<Dropdown isOpen={dropdownOpen} toggle={toggle}>
			<DropdownToggle className="label-class" caret>{selectedVoice}</DropdownToggle>
			<DropdownMenu>
				{voiceOptions.map((item, i) => {
					if (item.label !== selectedVoice) {
						return (
							<DropdownItem
								key={i}
								onClick={() => onSelect(item)}
							>{item.label}</DropdownItem>
						)
					}
				})}
			</DropdownMenu>
		</Dropdown>
	);
}