import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
  Alert,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
} from "reactstrap";
import ReactGA from "react-ga";
import ReactPixel from "react-facebook-pixel";
import { Redirect, NavLink } from "react-router-dom";
import { store } from "store/index";
import { setAlert, setLoader } from "action/index.js";
import SimpleReactValidator from "simple-react-validator";
import "components/custom.css";
/*import DatePicker from "react-datepicker";*/
import { Helmet } from "react-helmet";
import "react-datepicker/dist/react-datepicker.css";
import API from "api.js";
import DatePicker from "react-date-picker";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import { GoogleLogin } from '@react-oauth/google';
import FacebookLogin from 'react-facebook-login';
import { setToken, setUser, setLogo, setSub} from 'action/index.js';
import { connect } from "react-redux";
import { isMobile } from "react-device-detect";
import GoogleBtn from "components/GoogleBtn/GoogleBtn";
import PhoneInputField from 'components/Inputs/PhoneInputField';

const mapStateToProps = (state) => {
  return {
     token: state.token
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
     setToken: (tok) => dispatch(setToken(tok)),
     setUser: (user) => dispatch(setUser(user)),
     setSub: (sub) => dispatch(setSub(sub)),
     setLogo: (sub) => dispatch(setLogo(sub))
  };
};
class Register extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      cpassword: "",
      phone: "",
      userName: "",
      firstName: "",
      lastName: "",
      countryState: "",
      dob: "",
      center: "",
      score: "",
      otp: "",
      isOtp: false,
      isInstitude: true,
      login: false,
      error: null,
      modal: false,
      error_bag: null,
      dobs: "",
    };
    this.validator = new SimpleReactValidator();
  }
  componentDidMount() {
    ReactGA.initialize("UA-157984080-1");
    ReactGA.pageview(window.location.pathname + window.location.search);
    ReactPixel.init("2474090032742391");
    ReactPixel.init("197333187452630");
    ReactPixel.pageView();
    document.body.classList.toggle("register-page");
    if (store.getState().token !== null && store.getState().token !== "null") {
      this.props.history.push("/admin/dashboard");
    }
  }
  componentWillUnmount() {
    document.body.classList.toggle("register-page");
  }

  sendOTP = async (e) => {
    if (!this.validator.allValid()) {
      this.validator.showMessages();
      this.forceUpdate();
      return;
    }

    this.setState({ error_bag: null });
    e.preventDefault();
    var bodyFormData = new FormData();
    bodyFormData.set("email", this.state.email);
    bodyFormData.set("password", this.state.password);
    bodyFormData.set("user_name", this.state.userName);
    bodyFormData.set("center", this.state.isInstitude ? this.state.center : "");
    bodyFormData.set("phone", this.state.phone);
    bodyFormData.set("score", this.state.score);
    bodyFormData.set("first_name", this.state.firstName);
    bodyFormData.set("last_name", this.state.lastName);
    bodyFormData.set("dob", this.state.dob);
    bodyFormData.set("country_citizenship", this.state.country_citizenship);
    bodyFormData.set("country_residence", this.state.country_residence);
    bodyFormData.set("country_state", this.state.countryState);
    let res = null;
    try {
      res = await API({
        method: "POST",
        url: "/send/otp",
        data: bodyFormData,
      });
    } catch (error) {
      this.setState({ error: { error } });
    }
    console.log(res);
    if (res.status === 200) {
      this.setState({ isOtp: true });
      store.dispatch(
        setAlert({
          flag: true,
          type: 1,
          message: "Please check your email for verification code.",
        })
      );
      console.log(res);
      //store.dispatch(setAlert({'flag':true,'type':1,'message':res.data.message}));
    } else if (res.response.status === 422) {
      this.setState({ error: res.response });
      let str = "";
      Object.values(this.state.error.data.errors).forEach((val) => {
        str += val[0] + "\n";
      });
      this.setState({ error_bag: str });
    }
  };

  facebookSignUp = (response) => {
    console.log(response);
    API({
      method: "POST",
      url: "/fbSignUp",
      data: response
    }).then((res) => {
      console.log(res);
      this.afterRequest(res);
    }).catch((err)=> this.setState({error_bag:"Soemthing went wrong. Please try again later.",loginLock:false}));
  };
  

  googleSignUp = (credentialResponse) => {
    store.dispatch(setLoader(false));
    API({
      method: "POST",
      url: "/googleSignUp",
      data: credentialResponse
    }).then((res) => {
      console.log(res)
      this.afterRequest(res);
    }).catch((err)=> {
      this.setState({error_bag:"Soemthing went wrong. Please try again later.",loginLock:false});
    })
  }

  afterRequest = (res) => {
    if (res.status === 200) {
      const expires = new Date();
      expires.setDate(Date.now() + 1000 * 60 * 60);
      this.props.setToken(res.data.original.access_token);
      this.props.setUser(res.data.original.user);
      this.props.setLogo(res.data.original.image);
      let sub  = (res.data.original.user.active_subscription.length === 0)?false:true;
      this.props.setSub(sub);
      console.log(res.status === 200);
      if (res.status === 200) {
        console.log(res.status)
        // window.location.href  = "/admin/dashboard";
        this.setState({ login: true });
        //this.setState({login:true,loginLock:false});
      }

    }else if(res.response.status === 422){
      this.setState({error:res.response });
      let str = '';

      Object.values(this.state.error.data.errors).forEach((val) => { str += val[0]+'\n'; })
      this.setState({error_bag:str,loginLock:false});

    }
    else if (res.response.status === 402) {
      this.setState({error_bag:"Soemthing went wrong. Please try again later.",loginLock:false});
    }
  }

  sendOTP1 = async (e) => {
    this.setState({ error_bag: null });
    e.preventDefault();
    var bodyFormData = new FormData();
    bodyFormData.set("email", this.state.email);
    bodyFormData.set("password", this.state.password);
    bodyFormData.set("center", this.state.isInstitude ? this.state.center : "");
    bodyFormData.set("phone", this.state.phone);
    bodyFormData.set("score", this.state.score);
    bodyFormData.set("first_name", this.state.firstName);
    bodyFormData.set("last_name", this.state.lastName);
    bodyFormData.set("dob", this.state.dob);
    bodyFormData.set("country_citizenship", this.state.country_citizenship);
    bodyFormData.set("country_residence", this.state.country_residence);
    bodyFormData.set("country_state", this.state.countryState);
    let res = null;
    try {
      res = await API({
        method: "POST",
        url: "/send/otp",
        data: bodyFormData,
      });
    } catch (error) {
      this.setState({ error: { error } });
    }
    console.log(res);
    if (res.status === 200) {
      this.setState({ isOtp: true });
      store.dispatch(
        setAlert({
          flag: true,
          type: 1,
          message: "Please check your email for verification code.",
        })
      );
      console.log(res);
      //store.dispatch(setAlert({'flag':true,'type':1,'message':res.data.message}));
    } else if (res.response.status === 422) {
      this.setState({ error: res.response });
      let str = "";
      Object.values(this.state.error.data.errors).forEach((val) => {
        str += val[0] + "\n";
      });
      this.setState({ error_bag: str });
    }
  };

  postSignUp = async (e) => {
    if (!this.validator.allValid()) {
      this.validator.showMessages();
      this.forceUpdate();
      return;
    }
    this.setState({ error_bag: null });
    e.preventDefault();
    let cred = {
      email: this.state.email,
      password: this.state.password,
    };
    var bodyFormData = new FormData();
    bodyFormData.set("email", this.state.email);
    bodyFormData.set("password", this.state.password);
    // bodyFormData.set("center", this.state.isInstitude ? this.state.center : "");
    bodyFormData.set("phone", this.state.phone);
    // bodyFormData.set("score", this.state.score);
    bodyFormData.set("first_name", this.state.firstName);
    bodyFormData.set("last_name", this.state.lastName);
    // bodyFormData.set("dob", this.state.dob);
    bodyFormData.set("otp", this.state.otp);
    // bodyFormData.set("country_citizenship", this.state.country_citizenship);
    // bodyFormData.set("country_residence", this.state.country_residence);
    // bodyFormData.set("country_state", this.state.countryState);
    let res = null;
    try {
      res = await API({
        method: "POST",
        url: "/signup",
        data: bodyFormData,
      });
    } catch (error) {
      this.setState({ error: { error } });
    }
    console.log(res);
    if (res.status === 200) {
      this.setState({ login: true });
      console.log(res.data);
      // set token and data to state
      const expires = new Date();
      expires.setDate(Date.now() + 1000 * 60 * 60);
      this.props.setToken(res.data.data.access_token);
      this.props.setUser(res.data.data.user);
      this.props.setLogo(res.data.data.image);
      let sub = (res.data.data.user.active_subscription.length === 0)?false:true;
      this.props.setSub(sub);
      // -------------------------------

      store.dispatch(
        setAlert({ flag: true, type: 1, message: res.data.message })
      );
    } else if (res.status === 202) {
      store.dispatch(
        setAlert({ flag: true, type: 2, message: res.data.message })
      );
    } else if (res.response.status === 422) {
      this.setState({ error: res.response });
      let str = "";
      Object.values(this.state.error.data.errors).forEach((val) => {
        str += val[0] + "\n";
      });
      this.setState({ error_bag: str });
    }
  };

  changeInput = (e) => {
    let { name, value } = e.target;
    this.setState({ [name]: value });
  };

  toggleModal = (e) => {
    this.setState({ modal: !this.state.modal });
  };

  changeCheckInput = (e) => {
    let { name, value } = e.target;
    this.setState({ [name]: !this.state.isInstitude });
  };

  changeDate = (date) => {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    console.log([year, month, day].join("-"));
    this.setState({ dob: [year, month, day].join("-"), dobs: date });
  };

  selectCountry(val) {
    this.setState({ country_citizenship: val });
  }

  selectCountryResidence(val) {
    this.setState({ country_residence: val, countryState: "" });
  }

  selectRegion(val) {
    this.setState({ countryState: val });
  }

  handlePhoneChange = (event) => {
    this.setState({phone: event});
  }

  render() {
    if (this.state.login) {
      return <Redirect to="/register-success" />;
    }
    return (
      <React.Fragment>
        <Helmet>
          <title>Language Academy Registration | Join Us Today</title>
          <meta name="twitter:title" content="Register Language Academy" />
          <meta property="og:title" content="Register Language Academy" />
          <meta
            name="description"
            content="Register your free account now at Language Academy to obtain your Free Mock Test Material."
          />

          <meta
            name="twitter:description"
            content="Register your free account now at Language Academy to obtain your Free Mock Test Material."
          />
          <meta
            property="og:description"
            content="Register your free account now at Language Academy to obtain your Free Mock Test Material."
          />
        </Helmet>
        <div className="innerPage">
          <Modal isOpen={this.state.modal} size="lg">
            <ModalBody>
              <Table>
                <tr>
                  <td>
                    Person Aiming for 5 bands, if he get 36 in overall in full
                    mock test, or 30+ in reading test or 50+ in listening mock
                    then select this option in dropdown.
                  </td>
                </tr>
                <tr>
                  <td>
                    Person Aiming for 5.5 bands, if he get 42 in overall in full
                    mock test, or 30+ in reading test or 55+ in listening mock
                    then select this option in dropdown.
                  </td>
                </tr>
                <tr>
                  <td>
                    Person Aiming for 6 bands, if he get 46 in overall in full
                    mock test, or 35+ in reading test or 50+ in listening mock
                    then select this option in dropdown.
                  </td>
                </tr>
                <tr>
                  <td>
                    Person Aiming for 6.5 bands, if he get 50 in overall in full
                    mock test, or 40+ in reading test or 55+ in listening mock
                    then select this option in dropdown.
                  </td>
                </tr>
                <tr>
                  <td>
                    Person Aiming for 7 bands, if he get 55 in overall in full
                    mock test, or 45+ in reading test or 65+ in listening mock
                    then select this option in dropdown.
                  </td>
                </tr>
                <tr>
                  <td>
                    Person Aiming for 7.5 bands, if he get 62 in overall in full
                    mock test, or 50+ in reading test or 70+ in listening mock
                    then select this option in dropdown.
                  </td>
                </tr>
                <tr>
                  <td>
                    Person Aiming for 8 bands, if he get 68+ in overall in full
                    mock test, or 55+ in reading test or 75+ in listening mock
                    then select this option in dropdown.
                  </td>
                </tr>
              </Table>
            </ModalBody>
            <ModalFooter>
              <Button
                color="secondary"
                className="modalCloseBtn"
                onClick={this.toggleModal}
              >
                Close
              </Button>
            </ModalFooter>
          </Modal>
          <section className="bannerOuter">
            <div className="container">
              <div className="row">
                <div className="col-sm-12 text-center">
                  <h1>Create Account</h1>
                  <span>
                    Register now to obtain your free Mock Test Material
                  </span>
                </div>
              </div>
            </div>
          </section>
          {!this.state.isOtp && (
            <section className="contentOuter">
              <div className="container">
                <div className="row">
                  <div className="col-sm-12 registerForm">
                    {this.state.error_bag && (
                      <Alert color="warning">{this.state.error_bag}</Alert>
                    )}

                    {/* <div className="fieldOuter col-md-6">
                      <label>First Name</label>
                      <Input
                        placeholder="First Name"
                        type="text"
                        name="firstName"
                        value={this.state.firstName}
                        onChange={(e) => {
                          this.changeInput(e);
                        }}
                      />
                      {this.validator.message(
                        "First name",
                        this.state.firstName,
                        "required"
                      )}
                    </div>
                    <div className="fieldOuter col-md-6">
                      <label>Last Name</label>
                      <Input
                        placeholder="Last Name"
                        type="text"
                        name="lastName"
                        value={this.state.lastName}
                        onChange={(e) => {
                          this.changeInput(e);
                        }}
                      />
                      {this.validator.message(
                        "Last name",
                        this.state.lastName,
                        "required"
                      )}
                    </div> */}
                    <div className="fieldOuter col-md-6">
                      <label>Email</label>
                      <Input
                        placeholder="Email"
                        type="email"
                        name="email"
                        value={this.state.email}
                        onChange={(e) => {
                          this.changeInput(e);
                        }}
                      />
                      {this.validator.message(
                        "Email",
                        this.state.email,
                        "required|email"
                      )}
                    </div>
                    <div className="fieldOuter col-md-6">
                      <label>Phone</label>
                      {/*<PhoneInputField
                        value={this.state.phone}
                        changeEvent={this.handlePhoneChange}
                        addClass="register-page"
                      />*/}
                      <Input
                        placeholder="Phone"
                        type="number"
                        name="phone"
                        value={this.state.phone}
                        onChange={(e) => {
                          this.changeInput(e);
                        }}
                      />
                      {this.validator.message(
                        "Phone",
                        this.state.phone,
                        "required"
                      )}
                    </div>
                    {/* 
                    <div className="fieldOuter col-md-6">
                      <label>DOB</label>
                      <Input
                        placeholder="D.O.B (YYYY-MM-DD)"
                        type="date"
                        name="dob"
                        value={this.state.dob}
                        onChange={(e) => {
                          this.changeInput(e);
                        }}
                      />

                      {this.validator.message(
                        "dob",
                        this.state.dob,
                        "required"
                      )}
                    </div>
                    <div className="fieldOuter col-md-6">
                      <label>Country of Citizenship</label>
                      <CountryDropdown
                        value={this.state.country_citizenship}
                        onChange={(val) => this.selectCountry(val)}
                        name="country_citizenship"
                      />
                      {this.validator.message(
                        "Country of citizenship",
                        this.state.country_citizenship,
                        "required"
                      )}
                    </div>
                    <div className="fieldOuter col-md-6">
                      <label>Country of residence</label>
                      <CountryDropdown
                        value={this.state.country_residence}
                        onChange={(val) => this.selectCountryResidence(val)}
                        name="country_residence"
                      />
                      {this.validator.message(
                        "Country of residence",
                        this.state.country_residence,
                        "required"
                      )}
                    </div>
                    <div className="fieldOuter col-md-6">
                      <label>State (of Country of residence)</label>
                      <RegionDropdown
                        country={this.state.country_residence}
                        value={this.state.countryState}
                        onChange={(val) => this.selectRegion(val)}
                        name="countryState"
                      />
                      {this.validator.message(
                        "State",
                        this.state.countryState,
                        "required"
                      )}
                    </div> */}
                    <div className="fieldOuter col-md-6">
                      <label>Password</label>
                      <Input
                        placeholder="Password"
                        type="password"
                        name="password"
                        value={this.state.password}
                        onChange={(e) => {
                          this.changeInput(e);
                        }}
                      />
                      {this.validator.message(
                        "Password",
                        this.state.password,
                        "required"
                      )}
                    </div>
                    <div className="fieldOuter col-md-6">
                      <label>Confirm Password</label>
                      <Input
                        placeholder="Confirm Password"
                        type="password"
                        name="cpassword"
                        value={this.state.cpassword}
                        onChange={(e) => {
                          this.changeInput(e);
                        }}
                      />
                      {this.validator.message(
                        "Confirm password",
                        this.state.cpassword,
                        "required"
                      )}
                    </div>
                    <br />

                    {/* {this.state.isInstitude && (
                      <React.Fragment>
                        <div className="fieldOuter col-md-6">
                          <label>Branch</label>
                          <select
                            onChange={(e) => {
                              this.changeInput(e);
                            }}
                            name="center"
                          >
                            <option value="">Select branch</option>
                            <option value="LA Parramatta Branch">
                              LA Parramatta Branch
                            </option>
                            <option value="LA Sydney CBD Branch">
                              LA Sydney CBD Branch
                            </option>
                            <option value="LA Jalandhar Branch">
                              LA Jalandhar Branch
                            </option>
                            <option value="LA Gurdaspur Branch">
                              LA Gurdaspur Branch
                            </option>
                            <option value="LA Amritsar Branch">
                              LA Amritsar Branch
                            </option>
                            <option value="LA Sirsa Branch">
                              LA Sirsa Branch
                            </option>
                            <option value="LA Sri Ganganagar Branch">
                              LA Sri Ganganagar Branch
                            </option>
                            <option value="LA Kurukshetra Branch">
                              LA Kurukshetra Branch
                            </option>
                            <option value="LA Online Student">
                              LA Online Student
                            </option>
                            <option value="Not a student with LA">
                              Not a student with LA
                            </option>
                          </select>
                          <em className="info-txt ml-1">
                            *this information must be 100% appropriate otherwise
                            your account may be blocked
                          </em>
                          {this.validator.message(
                            "institute center",
                            this.state.center,
                            "required"
                          )}
                        </div>
                      </React.Fragment>
                    )}
                    <React.Fragment>
                      <div className="fieldOuter col-md-6">
                        <label>Score</label>
                        <select
                          onChange={(e) => {
                            this.changeInput(e);
                          }}
                          name="score"
                        >
                          <option value="">Select desired score</option>
                          <option value="36">36+ (5 Bands)</option>
                          <option value="42">42+ (5.5 Bands)</option>
                          <option value="50">50+ (6 Bands)</option>
                          <option value="58">58+ (6.5 Bands)</option>
                          <option value="65">65+ (7 Bands)</option>
                          <option value="73">73+ (7.5 Bands)</option>
                          <option value="79">79+ (8 Bands)</option>
                        </select> */}
                        {/* <i style={{margin:'22px 3px'}} onClick={this.toggleModal} className="fa fa-info-circle" aria-hidden="true"></i>
                      {this.validator.message('desired score', this.state.score, 'required')} */}
                     {/* </div>
                    </React.Fragment>*/}
                    <div className="fieldOuter text-center mb-0 w-100">
                      <button
                        className="themeBtn blueBtn"
                        onClick={this.sendOTP}
                      >
                        Signup
                      </button>
                    </div>
                    <div className="separator">
                      <hr className="hr"/>
                      <span>OR</span>
                      <hr className="hr"/>
                    </div>
                    {/* <div className="google-btn mb-3">
                      <GoogleLogin
                        text="signup_with"
                        size="medium"
                        width={isMobile ? "200" : "400"}
                        onSuccess={this.googleSignUp}
                        onError={() => {
                          console.log('Login Failed');
                        }}
                      />
                    </div> */}
                    <div className="text-center">
                      <GoogleBtn afterLogin={this.afterRequest} />
                    </div>
                    {/* <div className="fb-parent">
                      <div className="fb-child">
                        <FacebookLogin
                          appId={process.env.REACT_APP_FACEBOOK_APP_ID}
                          autoLoad={false}
                          fields="name,email,picture"
                          callback={this.facebookSignUp}
                          icon="fa-facebook"
                          cookie={false}
                          textButton="Sign up with Facebook"
                          isMobile={false}
                        />
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </section>
          )}
          {this.state.isOtp && (
            <section className="contentOuter">
              <div className="container">
                <div className="row">
                  <div className="col-sm-12 registerForm">
                    <div className="alert alert-success">
                      Please check your email for Verification Code.
                    </div>
                    {this.state.error_bag && (
                      <Alert color="warning">{this.state.error_bag}</Alert>
                    )}
                    <div className="fieldOuter">
                      <Input
                        placeholder="Enter Verification Code"
                        type="text"
                        name="otp"
                        value={this.state.otp}
                        onChange={(e) => {
                          this.changeInput(e);
                        }}
                      />
                      {this.validator.message(
                        "otp",
                        this.state.otp,
                        "required"
                      )}
                      <a
                        href="javascript:void(0)"
                        className="text-primary"
                        onClick={this.sendOTP1}
                      >
                        Resend Verification Code
                      </a>
                    </div>

                    <div className="fieldOuter text-center mb-0">
                      <button
                        className="themeBtn blueBtn"
                        onClick={this.postSignUp}
                      >
                        SIGN UP
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          )}
        </div>
      </React.Fragment>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Register);
