export default function convertTo12Hrs(_date) {
  const date = new Date(_date.replace(/-/g, "/"));
  let numDate = date.getDate();
  if (numDate < 10) {
    numDate = "0" + numDate;
  }
  let month = date.getMonth() + 1;
  if (month < 10) {
    month = "0" + month;
  }
  let year = date.getFullYear();
  let hours = date.getHours();
  let minutes = date.getMinutes();
  let ampm = hours >= 12 ? "pm" : "am";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? "0" + minutes : minutes;
  let strDate = `${year}-${month}-${numDate} ${hours}:${minutes} ${ampm}`;
  return strDate;
}
