import React from "react";
import Header from "../components/Frontend/Header";
import Footer from "../components/Frontend/Footer";
import VideoCourseImage from "../assets/images/video-course.jpg";
import VideoTestimonial from "../components/Frontend/VideoPTETestimonial";
import { Helmet } from "react-helmet";
import ReactGA from "react-ga";
import ReactPixel from "react-facebook-pixel";
import API from "../api";
import { store } from "store/index";
import { setAlert } from "action/index";
import { getCountryForTimezone } from "countries-and-timezones";
import BuyBtn from "components/VideoCourse/BuyBtn";
import Plans from "components/VideoCourse/Plans";
import { Link } from "react-router-dom";

class VideoCourse extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      plans: [],
      country: "AU",
    };
  }
  componentDidMount() {
    ReactGA.initialize("UA-157984080-1");
    ReactGA.pageview(window.location.pathname + window.location.search);
    ReactPixel.init("2474090032742391");
    ReactPixel.init("197333187452630");
    ReactPixel.pageView();
    if (window.location.href.indexOf("#packages") != -1) {
      window.location.hash = "";
      window.location.hash = "packages";
    }
    this.getPackages();
  }

  getPackages() {
    const country = getCountryForTimezone(
      Intl.DateTimeFormat().resolvedOptions().timeZone
    );
    let countryId = "AU";
    if (country && country.id) {
      countryId = country.id;
    }
    const url =
      process.env.REACT_APP_URL_ALT +
      "/api/v2/getPackages?countryId=" +
      countryId;
    API({
      url: url,
      method: "GET",
    }).then((res) => {
      if (res.status === 200) {
        const { data } = res.data;
        const plans = data.filter((plan) => plan.is_video !== 0);
        this.setState({ plans: plans, country: res.data.country });
      } else {
        store.dispatch(
          setAlert({ flag: true, type: 2, message: "Something went wrong." })
        );
      }
    });
  }

  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>Language Academy</title>
        </Helmet>
        <Header />
        <div className="innerPage vcPage">
          <div className="registerOuter">
            <div className="container">
              <div className="row registerInr">
                <div className="col-sm-12 text-center">
                  <h1 className="section-title">
                    Master PTE with Our <br />
                    Exclusive Video Course
                  </h1>
                  <p>
                    Welcome to our comprehensive PTE Video Course, available
                    exclusively <br />
                    with our Gold package.
                  </p>
                  <div className="col-md-12 text-center btnOuter">
                    <Link
                      to="/auth/register"
                      className="btn ss-btn outlineBtn"
                      data-animation="fadeInUp"
                      data-delay=".8s"
                    >
                      <span>REGISTER NOW</span>
                    </Link>
                  </div>
                  <div className="courseVideo">
                    <img
                      src={VideoCourseImage}
                      alt="video-cover02"
                      style={{ borderRadius: "1rem" }}
                    />
                  </div>
                  <div className="vCourseInfo">
                    <p>
                      Developed by <strong>Varun Dhawan</strong>, an esteemed
                      educator and a 12-time scorer of perfect 90 in PTE <br />
                      Academic, this course is designed to equip you with the
                      skills needed to excel in
                    </p>
                    <div className="courseList">
                      <ul>
                        <li>
                          <a href="" className="red">
                            Speaking
                          </a>
                        </li>
                        <li>
                          <a href="" className="blue">
                            Listening
                          </a>
                        </li>
                        <li>
                          <a href="" className="green">
                            Reading
                          </a>
                        </li>
                        <li>
                          <a href="" className="skyBlue">
                            Writing
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="whyChooseOuter">
            <div className="container">
              <div className="row whyChooseInr">
                <div className="col-sm-12 text-center">
                  <div className="section-title la-free-mt-app-title-div">
                    <h2 className="text-center text-white">
                      Why Choose <br />
                      Our Video Course?
                    </h2>
                  </div>
                  <div className="impFeatureBlock">
                    <div className="whyChooseCntnt impFeatureBlockInr">
                      <div className="wcCntntInr ifBlockInrMain">
                        <div className="impFeatListItem">
                          <div className="impFeatListHead">
                            <i className="fa fa-check-circle"></i> &nbsp;
                            <b>Expert Instruction</b>
                          </div>
                          <p>
                            Benefit from Varun Dhawan's extensive teaching
                            experience spanning over 6 years and his
                            unparalleled success in guiding 50,000+ students to
                            PTE success.
                          </p>
                        </div>
                        <div className="impFeatListItem">
                          <div className="impFeatListHead">
                            <i className="fa fa-check-circle"></i> &nbsp;
                            <b>Proven Strategies</b>
                          </div>
                          <p>
                            Learn Varun's tried-and-tested tips and tricks
                            guaranteed to enhance your performance in the exam.
                          </p>
                        </div>
                        <div className="impFeatListItem">
                          <div className="impFeatListHead">
                            <i className="fa fa-check-circle"></i> &nbsp;
                            <b>Comprehensive Coverage</b>
                          </div>
                          <p>
                            Access detailed video tutorials covering every
                            aspect of the PTE tasks.
                          </p>
                        </div>
                        <div className="impFeatListItem">
                          <div className="impFeatListHead">
                            <i className="fa fa-check-circle"></i> &nbsp;
                            <b>Exclusive Access</b>
                          </div>
                          <p>
                            Available only with our Gold package, ensuring you
                            receive the highest quality instruction.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="la-free-mt-app-second-section la-free-mt-app-features-section scoresOuter includedOuter">
            <div className="container">
              <div className="row justify-content-center la-free-mt-app-title-row">
                <div className="col-xl-12 col-lg-12 la-free-mt-app-title-col">
                  <div className="section-title la-free-mt-app-title-div mb-45">
                    <h2 className="text-center">What's Included</h2>
                  </div>
                </div>
              </div>
              <div className="row text-center">
                <div className="col-md-4 la-free-mt-app-features-innerr-col1 la-free-mt-app-features-innerr-col">
                  <div className="scoringFeatCard">
                    <div className="la-inner-col sfCardMain">
                      <img src="images/edit-icon.png" alt="edit-icon" />
                      <h2>Detailed Task Insights</h2>
                      <p>
                        Dive deep into each PTE task with comprehensive video
                        modules.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-4  la-free-mt-app-features-innerr-col2 la-free-mt-app-features-innerr-col">
                  <div className="scoringFeatCard">
                    <div className="la-inner-col sfCardMain">
                      <img src="images/score-review.png" alt="score-review" />
                      <h2>Exam Strategies</h2>
                      <p>
                        Master effective strategies tailored to each section of
                        the exam.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 la-free-mt-app-features-innerr-col3 la-free-mt-app-features-innerr-col">
                  <div className="scoringFeatCard">
                    <div className="la-inner-col sfCardMain">
                      <img src="images/scoring-card.png" alt="scoring-card" />
                      <h2>Varun Dhawan's Expertise</h2>
                      <p>
                        Learn from Varun's personal insights and strategies that
                        have consistently delivered outstanding results.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 text-center btnOuter">
                  <BuyBtn />
                </div>
              </div>
            </div>
          </div>
          <section className="inner-page-fourth-section laHonesOuter">
            <div className="container">
              <div className="sec-title">
                <h2 className="title-h2">
                  Start Your Journey to <span>PTE</span> Success Today
                </h2>
              </div>
              <div className="row">
                <div className="col-md-6 tabContentRowcol">
                  {/* <img src={VarunImage} alt="video-cover02" /> */}
                  <iframe
                    src="https://player.vimeo.com/video/984725169?h=e48ced1845&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                    frameborder="0"
                    width={620}
                    height={300}
                  ></iframe>
                </div>
                <div className="col-md-6 tabLinkRowcol">
                  <div className="ed_tab">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="goldUpdateCard">
                          <h3>
                            UPGRADE TO OUR <span>GOLD PACKAGE</span>
                          </h3>
                          <p>
                            To unlock Varun Dhawan's acclaimed video course and
                            take your PTE preparation to the next level.
                          </p>
                        </div>
                      </div>
                      <div className="col-md-12 questionTypeCard">
                        <div className="qtCardInr">
                          <div className="qtCardCntnt">
                            <h4>
                              Achieve your dream PTE score with confidence.
                            </h4>
                            <div className="col-md-12 btnOuter p-0">
                              <BuyBtn />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="package pt-0 mb-0 packagesOuter" id="packages">
            <div className="container">
              <div className="row">
                <div className="col">
                  <div className="section-title la-free-mt-app-title-div text-center">
                    <h2>Package</h2>
                    <p>
                      The Gold Package offered by Language Academy includes
                      study materials, complimentary mock tests with scorecards,
                      and thorough feedback.
                    </p>
                  </div>
                </div>
              </div>
              <div className="row packagesCntnt">
                <div className="col-md-5 packsCard bestPack">
                  <div className="packsCardInr">
                    <div className="packsCardMain">
                      <span>BEST SELLER</span>
                      <div className="packHead">
                        <div className="packHeadInr">
                          <div className="packIcon">
                            <img src="images/achedamy-logo-03.png" />
                          </div>
                          <h3>GOLD</h3>
                          <div className="packRadio">
                            <div className="packRadioInr">
                              <i className="fa fa-check-circle"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="packBody">
                        <ul>
                          <li>
                            <i className="fa fa-check-circle"></i>{" "}
                            <span>
                              Unlimited AI scoring for all question types
                            </span>
                          </li>
                          <li>
                            <i className="fa fa-check-circle"></i>{" "}
                            <span>Full access to them exam questions</span>
                          </li>
                          <li>
                            <i className="fa fa-check-circle"></i>{" "}
                            <span>
                              Access to latest templates and prediction files
                            </span>
                          </li>
                          <li>
                            <i className="fa fa-check-circle"></i>{" "}
                            <span>
                              Unlimited sectional mock tests with scoring
                            </span>
                          </li>
                          <li>
                            <i className="fa fa-check-circle"></i>{" "}
                            <span>Unlimited full mock tests with scoring</span>
                          </li>
                          <li>
                            <i className="fa fa-check-circle"></i>{" "}
                            <span>
                              Unlimited comprehensive mock tests with scoring
                            </span>
                          </li>
                          <li className="availInGold">
                            <i className="fa fa-check-circle"></i>{" "}
                            <span>Access to reading explanation videos</span>
                          </li>
                          <li className="availInGold">
                            <i className="fa fa-check-circle"></i>{" "}
                            <span>Full access to premium strategy videos</span>
                          </li>
                          <li className="availInGold">
                            <i className="fa fa-check-circle"></i>{" "}
                            <span>
                              Full access to the detailed video course
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <Plans plans={this.state.plans} country={this.state.country} />
              </div>
            </div>
          </section>
          <VideoTestimonial />
          <div className="faq faqOuter" id="faq">
            <div className="container">
              <div className="row">
                <div className="col la-free-mt-app-title-col">
                  <div className="section-title la-free-mt-app-title-div text-center">
                    <h2>Frequently Asked Questions</h2>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <div className="col-10 mx-auto">
                    <div className="accordion" id="faqExample">
                      <div className="customCard">
                        <div className="custom-card-header" id="headingOne">
                          <h5 className="mb-0">
                            <button
                              className="btn btn-link"
                              type="button"
                              data-toggle="collapse"
                              data-target="#collapseOne"
                              aria-expanded="true"
                              aria-controls="collapseOne"
                            >
                              What is the Pearson Test of English (PTE)?
                            </button>
                          </h5>
                        </div>
                        <div
                          id="collapseOne"
                          className="collapse show"
                          aria-labelledby="headingOne"
                          data-parent="#faqExample"
                        >
                          <div className="card-body">
                            The Pearson Test of English (PTE) is an
                            internationally recognized English language
                            proficiency test accepted by universities, colleges,
                            and governments around the world for study abroad
                            and immigration purposes.
                          </div>
                        </div>
                      </div>
                      <div className="customCard">
                        <div className="custom-card-header" id="headingOne">
                          <h5 className="mb-0">
                            <button
                              className="btn btn-link"
                              type="button"
                              data-toggle="collapse"
                              data-target="#collapseTwo"
                              aria-expanded="false"
                              aria-controls="collapseTwo"
                            >
                              Why should I choose Language Academy for PTE
                              preparation?
                            </button>
                          </h5>
                        </div>
                        <div
                          id="collapseTwo"
                          className="collapse"
                          aria-labelledby="headingOne"
                          data-parent="#faqExample"
                        >
                          <div className="card-body">
                            Language Academy offers comprehensive PTE
                            preparation courses that include study materials,
                            mock tests, and expert guidance to help you achieve
                            your target score. Our structured approach ensures
                            thorough preparation and confidence on test day.
                          </div>
                        </div>
                      </div>
                      <div className="customCard">
                        <div className="custom-card-header" id="headingOne">
                          <h5 className="mb-0">
                            <button
                              className="btn btn-link"
                              type="button"
                              data-toggle="collapse"
                              data-target="#collapseThree"
                              aria-expanded="false"
                              aria-controls="collapseThree"
                            >
                              Can I access Language Academy's PTE preparation
                              courses online?
                            </button>
                          </h5>
                        </div>
                        <div
                          id="collapseThree"
                          className="collapse"
                          aria-labelledby="headingOne"
                          data-parent="#faqExample"
                        >
                          <div className="card-body">
                            Yes, Language Academy offers online PTE preparation
                            courses that you can access from anywhere with an
                            internet connection. This flexibility allows you to
                            study at your own pace and schedule, fitting your
                            preparation around your other commitments.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="innerPage">
          <section className="bannerOuter">
            <div className="container">
              <div className="row">
                <div className="col-sm-12 text-center">
                  <h1>Excel In PTE With This Video Course</h1>
                  <span>
                    Complete Preparation Package To Achieve The Score You Desire
                  </span>
                  <br />
                  <a href="javascript:;" className="downArrow">
                    <img src="/images/banner-arrow.png" alt="banner-arrow" />
                  </a>
                </div>
              </div>
            </div>
          </section>
        </div>
        <section className="whyOnline">
          <div className="container">
            <div className="row">
              <div className="col-sm-6 whyVideo">
                <div className="videoOuter">
                  <iframe
                    style={{ height: "100%" }}
                    src="https://www.youtube.com/embed/KnOFM034W5U"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  ></iframe>
                </div>
              </div>
              <div className="col-sm-12 courseFeatures">
                <h2>Why Online Video Course?</h2>
                <ul className="checklist">
                  <li>24 hours access.</li>
                  <li>All the proven tips, templates and strategies.</li>
                  <li>Real exam practice questions.</li>
                  <li>Complete access tonline practice portal.</li>
                  <li>
                    3 full length scored mock text with score-report and
                    feedback.
                  </li>
                  <li>
                    One to one consultation session with the tutor. (30 minutes
                    each)
                  </li>
                  <li>Regular prediction files.</li>
                  <li>
                    Access to exam prediction files with highly repeated
                    questions.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        
        <Packages plans={this.state.plans} />
        <FameWall />
        <VideoTuts />
        <section className="refundPolicy">
          <div className="container">
            <div className="row">
              <div className="col">
                <div className="refundNote text-center">
                  <h4>Refund Policy</h4>
                  <p>
                    We don't offer any refund in any given circumstances.
                    Nevertheless, a refund is possible is in case you are
                    charged extra due to a technical problem. The refund request
                    should be initiated within 24 hours by sending an email with
                    all the details on{" "}
                    <a hrf="mailto:info@languageacademy.com.au">
                      info@languageacademy.com.au
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section> */}
        <Footer />
      </React.Fragment>
    );
  }
}

export default VideoCourse;
