import React from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Label,
  FormGroup,
  Form,
  Alert,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Col,
  Row
} from "reactstrap";
import cookie from 'react-cookies'
import { connect } from 'react-redux';
import { Helmet } from "react-helmet";
import "components/custom.css";
import API from "api.js";
import { Redirect, NavLink } from "react-router-dom";
import { setToken, setUser, setSub} from 'action/index.js';


class Forgot extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      login:false,
      error:null,
      error_bag:null,
      otpflag:false
    };
  }
  componentDidMount() {
    document.body.classList.toggle("login-page");
  }
  componentWillUnmount() {
    document.body.classList.toggle("login-page");
  }

  postLogIn = async(e) => {
    
    this.setState({error_bag:null});
    e.preventDefault();
    let cred = {
      email: this.state.email,
      password: this.state.password,
    };
    var bodyFormData = new FormData();
    bodyFormData.set('email', this.state.email);
    let res = null;
    try{
      res = await API({
        method: 'POST',
        url: '/forgot',
        data: bodyFormData
      })
    }catch(error) {

        
    };
    if (res.status === 200) {
      this.setState({otpflag:true,error_bag:"Verification code sent to your Email !"});
    }else if(res.response.status === 422){
      this.setState({error:res.response });
      let str = '';
      
      Object.values(this.state.error.data.errors).forEach((val) => { str += val[0]+'\
        '; })
      this.setState({error_bag:str});

    }
    else if (res.response.status === 402) {
      this.setState({error_bag:"Wrong credentials."});
    }
    
  }

  changeInput = (e) => {
    let { name, value } = e.target;
    this.setState({ [name] : value });
  };
  render() {
    const {token,setToken} = this.props;
    if (this.state.login) {
        return (<Redirect to="/admin/dashboard" />);
    }
    return (
      <React.Fragment>
        <Helmet>
          <title>Language Academy Password Recovery | Reset Your Password</title>
          <meta
            name="description"
            content="Forgot your Language Academy password? Reset it quickly and easily to regain access to your account and continue your learning."
          />
        </Helmet>

        <div className=" innerPage login-page">
          
          <section class="bannerOuter">
    <div class="container">
      <div class="row">
        <div class="col-sm-12 text-center">
          <h1>Forgot Password</h1>
          <span> </span>
        </div>
      </div>
    </div>
  </section>
  <section class="contentOuter">
    <div class="container">
      <div class="row">
        <div class="col-sm-7 loginForm">
          {this.state.error_bag && <Alert color="warning">{this.state.error_bag}</Alert>}
            <div class="fieldOuter">
              <Input 
                  placeholder="Email"
                  type="text"
                  name="email"
                  value={this.state.email}
                  onChange={(e) => { this.changeInput(e)} }
                />
                
            </div>
            
            <div class="rememberForgot">
              <div class="rememberMe">
                <input type="checkbox" name="" />
                <span>Remember me</span>
              </div>
              <div class="forgotPwd">
                <NavLink className="mt-5" to='/auth/login'><span>Back</span></NavLink>
              </div>
            </div>
            <div class="fieldOuter text-right">
              <Button
                className="themeBtn"
                onClick={(e) => {this.postLogIn(e)}}
              >
                Submit
              </Button>
              {this.state.otpflag && <NavLink to="/auth/resetPassword">Got verification code?</NavLink>}
            </div>
        </div>
        <div class="col-sm-5 registerLink text-center">
          <h3>New User?</h3>
          <p>Register now to obtain your<br /> Free Mock Test Material</p>
          <NavLink to="/auth/register" class="themeBtn blueBtn">Register</NavLink>
        </div>
      </div>
    </div>
  </section>
          
        </div>
      </React.Fragment>
    );
  }
}

export default Forgot;
