import React from "react";

// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";
import { Route, Switch } from "react-router-dom";

import AdminNavbar from "components/Navbars/AdminNavbar.jsx";
import Footer from "components/Footer/Footer.jsx";

//import Sidebar from "components/Sidebar/Sidebar.jsx";
 import Sidebar from "components/Sidebar/SidebarNew.jsx";

import PortalNavbar from "components/Navbars/PortalNavbar.jsx";
import PortalFooter from "components/Footer/PortalFooter.jsx";
import PortalSidebar from "components/Sidebar/PortalSidebar.jsx";
import { store } from 'store/index';
import routes from "routes.js";

import SidebarClose from "components/Sidebar/SidebarClose.jsx";

var ps;

class Admin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      backgroundColor: "black",
      activeColor: "info",
      sidebarMini: false
    };
  }
  componentDidMount() {
    if (navigator.platform.indexOf("Win") > -1) {
      document.documentElement.className += " perfect-scrollbar-on";
      document.documentElement.classList.remove("perfect-scrollbar-off");
      // ps = new PerfectScrollbar(this.refs.mainPanel);
    }
  }
  componentWillUnmount() {
    if (navigator.platform.indexOf("Win") > -1) {
      // ps.destroy();
      document.documentElement.className += " perfect-scrollbar-off";
      document.documentElement.classList.remove("perfect-scrollbar-on");
    }
  }
  componentDidUpdate(e) {
    if (e.history.action === "PUSH") {
      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
      // this.refs.mainPanel.scrollTop = 0;
    }
  }
  getRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return this.getRoutes(prop.views);
      }
      if (prop.layout === "/admin") {
        return (
          <Route
          path={prop.layout + prop.path}
          component={prop.component}
          key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  handleActiveClick = color => {
    this.setState({ activeColor: color });
  };
  handleBgClick = color => {
    this.setState({ backgroundColor: color });
  };
  handleMiniClick = () => {
    if (document.body.classList.contains("sidebar-mini")) {
      this.setState({ sidebarMini: false });
    } else {
      this.setState({ sidebarMini: true });
    }
    document.body.classList.toggle("sidebar-mini");
  };
  render() {
    return (
      <React.Fragment>
        {/*<SidebarClose />*/}
        {!store.getState().is_portal && store.getState().is_main && 
          <>
            <div className="portal container-fluid p-0">
              <div className="siteWrap">
                <Sidebar
                  {...this.props}
                  routes={routes}
                  bgColor={this.state.backgroundColor}
                  activeColor={this.state.activeColor}
                />
                <Switch>{this.getRoutes(routes)}</Switch>
              </div>
            </div>
            {this.props.location.pathname.indexOf("full-screen-map") !== -1 ? null : (
              <Footer fluid />
            )}
          </>
        }
        {(store.getState().is_portal || !store.getState().is_main) && 
          <div className="wrapper">
            <PortalSidebar
              {...this.props}
              routes={routes}
              bgColor={this.state.backgroundColor}
              activeColor={this.state.activeColor}
            />
            {/*<div className="main-panel" ref="mainPanel">*/}
            <div>
              <PortalNavbar {...this.props} handleMiniClick={this.handleMiniClick} />
              <Switch>{this.getRoutes(routes)}</Switch>
              {this.props.location.pathname.indexOf("full-screen-map") !== -1 ? null : (
                <PortalFooter fluid />
              )}
            </div>
          </div>
        }
      </React.Fragment>
    );
  }
}

export default Admin;