import React from 'react';
import Header from '../components/Frontend/Header';
import Footer from '../components/Frontend/Footer';
import API from "../api.js";
import { setAlert} from 'action/index.js';
import {store,persistor} from 'store/index';
import SimpleReactValidator from 'simple-react-validator';
import { Helmet } from "react-helmet";
import ReactGA from 'react-ga';
import { Redirect} from "react-router-dom";
import ReactPixel from 'react-facebook-pixel';
class NaatiAppLp extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name:'',
            email:"",
            phone:"",
            message:"",
            error:[],
            submit:false,
            flag:false,
            lockSubmit:false
        }
        this.validator = new SimpleReactValidator();
    }
    componentDidMount() {
        ReactGA.initialize('UA-157984080-1');
        ReactGA.pageview(window.location.pathname + window.location.search);
        ReactPixel.init('2474090032742391');
        ReactPixel.init('197333187452630');
        ReactPixel.pageView();
    }

    handleChange = (event) => {
        const { name, value } = event.target;
        this.setState({[name]:value});
    }

    submitForm = () =>{
        if (this.validator.allValid() && !this.state.lockSubmit) {
            this.setState({lockSubmit:true});
            let json = {
                "name": this.state.name,
                "email": this.state.email,
                "phone": this.state.phone,
                "message": this.state.message,
            }
            API({
              method: 'POST',
              url: "post/contact",
              data:json,
            }).then(data => {
              this.setState({submit:true,lockSubmit:false,name:'',email:'',phone:'',message:'',flag:true});

              store.dispatch(setAlert({'flag':true,'type':1,'message':data.data.response.message}));
            }).catch(()=>{
                this.setState({lockSubmit:false});
            });
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }
 render(){
 	if (this.state.flag) {
        return (<Redirect to="/thank-you" />);
    }
    return (
        <React.Fragment>
        <Helmet>
            <title>Language Academy</title>
            <meta name="twitter:title" content="Language Academy" />
            <meta property="og:title" content="Language Academy" />
        </Helmet>
        <div className="homePage">
            <Header />
        </div>
        <div className="innerPage">
            <section className="whiteMenuOuter">
            </section>
        </div>
        <section id="parallax" className="la-naati-app-first-section slider-area slider-bg2 second-slider-bg d-flex fix">
            <div className="slider-shape" data-depth="0.10"><img src="images/header-sape.png" alt="shape"/></div>
            <div className="slider-shape" data-depth="0.50"></div>
            <div className="container">
                <div className="row">
                    <div className="col-lg-6">
                        <div className="slider-content second-slider-content left-center">
                            <ul className="small-title mb-30">
                                <li>NAATI</li>
                                <li>Best Mobile App</li>
                            </ul>
                            <h2 data-animation="fadeInUp" data-delay=".4s">Ace & Smash NAATI CCL with LA CCL <span>The Best NAATI CCL Preparation App</span></h2>
                            <p data-animation="fadeInUp" data-delay=".6s">The app ensures that if you can’t access physical classes or miss your classes due to other important commitments like studies, coaching, work, or something else, your learning still keeps on going!</p>
                            <div className="slider-btn mt-30 mb-30">
                                <a href="/contact" className="btn ss-btn" data-animation="fadeInUp" data-delay=".8s">Contact Us</a>
                            </div>
                        </div>
                    </div>
                        <div className="col-lg-6">
                            <img src="images/LA-naati-section1-mob-icon-29-06-21.png" alt="shape" className="banner-image-obj s-img"/>
                    </div>
                </div>
            </div>
        </section>
        <section className="la-naati-app-second-section la-naati-app-features-section py-40">
            <div className="container">
                <div className="row justify-content-center la-naati-app-title-row la-features-row">
                    <div className="col-xl-12 col-lg-12 la-naati-app-title-col">
                        <div className="section-title la-naati-app-title-div text-center pl-40 pr-40 mb-30">
                            <h2>Amazing Features</h2>
                        </div>
                    </div>
                </div>
                <div className="row la-features-content-row">
                    <div className="col-md-3 la-naati-app-features-innerr-col1">
                        <div className="row">
                            <div className="col-md-9">
                                <h2 className="la-naati-app-features-text">2,000+ vocabulary in English and your native language</h2>
                            </div>
                            <div className="col-md-3 la-naati-app-features-image-col">
                                <img className="la-naati-app-features-image" src="images/LA-sec2-icon1-29-06-21.jpg"/>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-9">
                                <h2 className="la-naati-app-features-text">80+ practice dialogues with sample answers</h2>
                            </div>
                            <div className="col-md-3 la-naati-app-features-image-col">
                                <img className="la-naati-app-features-image" src="images/LA-sec2-icon2-29-06-21.jpg"/>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-9">
                                <h2 className="la-naati-app-features-text">Mock tests in a real-time environment with sample answers</h2>
                            </div>
                            <div className="col-md-3 la-naati-app-features-image-col">
                                <img className="la-naati-app-features-image" src="images/LA-Naati-sec2-icon3-29-06-21.jpg"/>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-9">
                                <h2 className="la-naati-app-features-text">Tips and strategies to crack NAATI CCL in the first go</h2>
                            </div>
                            <div className="col-md-3 la-naati-app-features-image-col">
                                <img className="la-naati-app-features-image" src="images/LA-naati-sec2-icon4-29-06-21.jpg"/>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6  la-naati-app-features-innerr-col2">
                        <img className="la-naati-app-features-innerr-col2-img" src="images/LA-sec2-mob-icon-29-06-21.png"/>
                    </div>
                    <div className="col-md-3 la-naati-app-features-innerr-col3">
                        <div className="row">
                            <div className="col-md-3 la-naati-app-features-image-col">
                                <img className="la-naati-app-features-image" src="images/LA-naati-sec2-icon5-29-06-21.jpg"/>
                            </div>
                            <div className="col-md-9">
                                <h2 className="la-naati-app-features-text">Easy to access – Can we write something catchy</h2>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-3 la-naati-app-features-image-col">
                                <img className="la-naati-app-features-image" src="images/la-naati-icon6-29-06-21.jpg"/>
                            </div>
                            <div className="col-md-9">
                                <h2 className="la-naati-app-features-text">Vocabulary in 7 different categories</h2>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-3 la-naati-app-features-image-col">
                                <img className="la-naati-app-features-image" src="images/LA-naati-icon8-29-06-21.jpg"/>
                            </div>
                            <div className="col-md-9">
                                <h2 className="la-naati-app-features-text">Last-minute practice package</h2>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-3 la-naati-app-features-image-col">
                                <img className="la-naati-app-features-image" src="images/LA-naati-icon9-29-06-21.jpg"/>
                            </div>
                            <div className="col-md-9">
                                <h2 className="la-naati-app-features-text">All the resources available even if you miss the class.</h2>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row la-vide-row">
                    <div className="col-md-1"></div>
                    <div className="col-md-10">
                        <div className="la-video-iframe">
                            <a href="https://www.youtube.com/watch?v=HQyQ8aXMQSo" title="NAATI App Features" className="popup-video">
                                <img src="images/naati-app-intro.jpg" alt="NAATI App Features"/>
                            </a>
                        </div>
                    </div>
                    <div className="col-md-1"></div>
                </div>
            </div>
        </section>
        <section className="la-naati-app-languages-section py-40" id="la-naati-app-languages-section">
            <div className="container">
                <div className="row justify-content-center la-naati-app-title-row">
                    <div className="col-xl-12 col-lg-12 la-naati-app-title-col">
                        <div className="section-title la-naati-app-title-div text-center pl-40 pr-40 mb-45">
                            <h2>Languages We Provide</h2>
                        </div>
                    </div>
                </div>
                <div className="row languages-border-top text-center languages-box-second-row">
                    <div className="col mx-1">
                        <div className="language-box">
                            <img src="images/LA-sec4-icon-hindi-29-06-21.png"/>
                            <h2>Hindi</h2>
                        </div>
                    </div>
                    <div className="col mx-1">
                        <div className="language-box">
                            <img src="images/LA-sec4-icon-marathi.png"/>
                            <h2>Marathi</h2>
                        </div>
                    </div>
                    <div className="col mx-1">
                        <div className="language-box">
                            <img src="images/LA-sec4-icon-punjabi-29-06-21.png"/>
                            <h2>Punjabi</h2>
                        </div>
                    </div>
                    <div className="col mx-1">
                        <div className="language-box">
                            <img src="images/LA-sec4-icon-nepali.png"/>
                            <h2>Nepali</h2>
                        </div>
                    </div>
                    <div className="col mx-1">
                        <div className="language-box">
                            <img src="images/LA-sec4-icon-sinhalese-29-06-21.png"/>
                            <h2>Sinhalese</h2>
                        </div>
                    </div>
                    <div className="col mx-1">
                        <div className="language-box">
                            <img src="images/LA-sec4-icon-gujarati-29-06-21.png"/>
                            <h2>Gujarati</h2>
                        </div>
                    </div>
                </div>
                <div className="row text-center languages-box-second-row">
                    <div className="col mx-1">
                        <div className="language-box">
                            <img src="images/LA-sec4-tamil-29-06-21.png"/>
                            <h2>Tamil</h2>
                        </div>
                    </div>
                    <div className="col mx-1">
                        <div className="language-box">
                            <img src="images/LA-sec4-telugu-icon-29-06-21.png"/>
                            <h2>Telugu</h2>
                        </div>
                    </div>
                    <div className="col mx-1">
                        <div className="language-box">
                            <img src="images/LA-sec4-urdu-12-08-21.png"/>
                            <h2>Urdu</h2>
                        </div>
                    </div>
                    <div className="col mx-1">
                        <div className="language-box">
                            <img src="images/LA-sec4-icon-malayalam.png"/>
                            <h2>Malayalam </h2>
                        </div>
                    </div>
                    <div className="col mx-1">
                        <div className="language-box">
                            <img src="images/LA-sec4-icon-bengali-29-06-21.png"/>
                            <h2>Bangla</h2>
                        </div>
                    </div>
                    <div className="col mx-1">
                        <div className="language-box">
                            <img src="images/LA-sec4-kannada-12-08-21.png"/>
                            <h2>Kannada</h2>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className="la-naati-app-free-tial-section py-40" id="la-naati-app-free-tial-section">
            <div className="container">
                <div className="row justify-content-center la-naati-app-title-row">
                    <div className="col-xl-12 col-lg-12 la-naati-app-title-col">
                        <div className="section-title la-naati-app-title-div text-center pl-40 pr-40 mb-45">
                            {/*<h2>Free Trial Version - Available for All</h2>
                            <p>Cracking NAATI CCL is no rocket science, but it does need a great amount of smart work. We understand the value of 5 points one gets by achieving good scores in NAATI CCL, and that’s why we offer lite access to LA CCL mobile app for everyone. In the Free version, you get 10 practice dialogues and Vocabulary. </p>*/}
                            <h2>Easy Mobile Access</h2>
                            <p>Cracking NAATI CCL is no rocket science, but it does need a great amount of smart work. We understand the value of 5 points one gets by achieving good scores in NAATI CCL, and that’s why we offer lite access to LA CCL mobile app for everyone.</p>
                        </div>
                    </div>
                </div>
                <div className="row la-naati-app-free-tial-applink-row text-center">
                    <div className="col-md-2"></div>
                    <div className="col-md-4">
                        <a className="btn btn-lg btn-naati-app" href="https://apps.apple.com/au/app/la-ccl/id1536541464" title="Install From App Store">
                            <i className="fa fa-apple"></i> Install From App Store
                        </a>
                    </div>
                    <div className="col-md-4">
                        <a className="btn btn-lg btn-naati-app" href="https://play.google.com/store/apps/details?id=com.languageacademy.naati&hl=en_AU&gl=US" title="Install From Google Play">
                            <i className="fa fa-play"></i> Install From Google Play
                        </a>
                    </div>
                    <div className="col-md-2"></div>
                </div>
            </div>
        </section>
        <section className="la-naati-app-testimonial-section py-40" id="la-naati-app-testimonial-section">
            <div className="container">
                <div className="row justify-content-center la-naati-app-title-row">
                    <div className="col-xl-12 col-lg-12 la-naati-app-title-col">
                        <div className="section-title la-naati-app-title-div text-center pl-40 pr-40">
                            <h2>Gracious words by Alumni</h2>
                        </div>
                    </div>
                </div>
                <div className="row la-naati-app-testimonial-mrow">
                    <div className="col-lg-12 la-naati-app-testimonial-mcol">
                        <div className="testimonial-active">
                            <div className="col-xl-4 la-naati-app-testimonial-col4">
                                <div className="single-testimonial">
                                    <div className="testi-author text-left">
                                        <div className="ta-info">
                                            <h6>Jashan Gill</h6>
                                        </div>
                                    </div>
                                    <p>Highly recommend Language Academy for NAATI...✌🏻😊<br />The best institute I have ever come across!!! Always been grateful for being part of LA!</p>

                                </div>
                            </div>
                                <div className="col-xl-4 la-naati-app-testimonial-col4">
                                <div className="single-testimonial">
                                    <div className="testi-author text-left">
                                        <div className="ta-info">
                                            <h6>Shiva Patel</h6>
                                        </div>
                                    </div>
                                        <p>Thanks LA team for guidance in NAATI preparation, all staff members are very polite and give us a personal attention in each matter.really appreciate their help</p>
                                </div>
                            </div>
                                <div className="col-xl-4 la-naati-app-testimonial-col4">
                                <div className="single-testimonial">
                                        <div className="testi-author text-left">
                                        <div className="ta-info">
                                            <h6>Paramjit Kaur Sandhu</h6>
                                        </div>
                                    </div>
                                    <p>Best coaching center for Naati...highly highly recommend</p>
                                </div>
                            </div>
                                <div className="col-xl-4 la-naati-app-testimonial-col4">
                                <div className="single-testimonial">
                                    <div className="testi-author text-left">
                                        <div className="ta-info">
                                            <h6>Ajay Shankar Reddy</h6>
                                        </div>
                                    </div>
                                        <p>Highly recommended to join LA. I cleared Naati and Pte with the help of LA team</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className="la-free-mt-app-testimonial-section py-40" id="la-free-mt-app-testimonial-section">
            <div className="container">
                <div className="row justify-content-center la-free-mt-app-title-row">
                    <div className="col-xl-12 col-lg-12 la-free-mt-app-title-col">
                        <div className="section-title la-free-mt-app-title-div text-center pl-40 pr-40">
                            <h2>Commendable Results of our NAATI CCL Test Takers!</h2>
                        </div>
                    </div>
                </div>
                <div className="row la-free-mt-app-testimonial-mrow">
                    <div className="col-lg-12 la-free-mt-app-testimonial-mcol  la-scorecard-testimonial">
                        <div className="testimonial-active">
                            <div className="col-xl-4 la-free-mt-app-testimonial-col4 la-scorecard-testimonial-col">
                                <div className="single-testimonial la-scorecard-testimonial">
                                    <img src="images/naati-ccl-bangla.jpeg" alt="NAATI CCL Bangla Scorecard"/>
                                </div>
                            </div>
                            <div className="col-xl-4 la-free-mt-app-testimonial-col4 la-scorecard-testimonial-col">
                                <div className="single-testimonial la-scorecard-testimonial">
                                    <img src="images/naati-ccl-gujarati.jpeg" alt="NAATI CCL Gujarati Scorecard"/>
                                </div>
                            </div>
                            <div className="col-xl-4 la-free-mt-app-testimonial-col4 la-scorecard-testimonial-col">
                                <div className="single-testimonial la-scorecard-testimonial">
                                        <img src="images/naati-ccl-hindi.jpeg" alt="NAATI CCL Hindi Scorecard"/>
                                </div>
                            </div>
                            <div className="col-xl-4 la-free-mt-app-testimonial-col4 la-scorecard-testimonial-col">
                                <div className="single-testimonial la-scorecard-testimonial">
                                    <img src="images/naati-ccl-malayalam.jpeg" alt="NAATI CCL Malayalam Scorecard"/>
                                </div>
                            </div>
                            <div className="col-xl-4 la-free-mt-app-testimonial-col4 la-scorecard-testimonial-col">
                                <div className="single-testimonial la-scorecard-testimonial">
                                    <img src="images/naati-ccl-nepali.jpeg" alt="NAATI CCL Nepali Scorecard"/>
                                </div>
                            </div>
                            <div className="col-xl-4 la-free-mt-app-testimonial-col4 la-scorecard-testimonial-col">
                                <div className="single-testimonial la-scorecard-testimonial">
                                    <img src="images/naati-ccl-tamil.jpeg" alt="NAATI CCL Tamil Scorecard"/>
                                </div>
                            </div>
                                <div className="col-xl-4 la-free-mt-app-testimonial-col4 la-scorecard-testimonial-col">
                                <div className="single-testimonial la-scorecard-testimonial">
                                    <img src="images/naati-ccl-telugu.jpeg" alt="NAATI CCL Telugu Scorecard"/>
                                </div>
                            </div>
                            <div className="col-xl-4 la-free-mt-app-testimonial-col4 la-scorecard-testimonial-col">
                                <div className="single-testimonial la-scorecard-testimonial">
                                    <img src="images/naati-ccl-urdu.jpeg" alt="NAATI CCL Urdu Scorecard"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className="newslater-area pt-90 pb-100 la-naati-app-hr-cta-section" id="la-naati-app-hr-cta-section">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-xl-12 col-lg-12">
                        <div className="section-title text-center pl-40 pr-40 mb-25">
                            <h2>Don't be Stranger</h2>
                            <p>With the LA CCL app designed for NAATI CCL test takers, you can get free NAATI CCL practice material, dialogues, vocabulary, tips, tricks, and strategies videos, and take the mock test as well. You can also add your NAATI CCL test date to keep a track of your progress</p>
                        </div>
                    </div>
                </div>
                <div className="row text-center">
                    <div className="col-xl-12 col-lg-12">
                        <a className="btn btn-lg btn-naati-app la-naati-app-hr-cta" href="/contact" target="_blank" title="Connect With Us">
                            <i className="fa fa-external-link"></i> Contact Us
                        </a>
                    </div>
                </div>
            </div>
        </section>
        <section id="contact" className="contact-area contact-bg  pt-50 pb-100 p-relative fix">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6">
                        <div className="section-title mb-40">
                            <h2>Contact Us</h2>
                                <p>Feel free to contact us if you need some help, consultation or you have some other questions.</p>
                        </div>
                            <div className="row">
                            <div className="col-lg-12">
                                <div className="contact-field p-relative c-name mb-20">
                                    <input type="text" placeholder="Name" name="name" onChange={this.handleChange} value={this.state.name}/>
                                    {this.validator.message('Name', this.state.name, 'required')}
                                </div>
                            </div>
                                <div className="col-lg-12">
                                <div className="contact-field p-relative c-email mb-20">
                                    <input type="text" placeholder="Email" onChange={this.handleChange} value={this.state.email} name="email" />
                                    {this.validator.message('Email', this.state.email, 'required|email')}
                                </div>
                            </div>
                                <div className="col-lg-12">
                                <div className="contact-field p-relative c-subject mb-20">
                                    <input type="text" placeholder="Phone" name="phone" onChange={this.handleChange} value={this.state.phone}/>
                                    {this.validator.message('Phone number', this.state.phone, 'required|number')}
                                </div>
                            </div>

                            <div className="col-lg-12">
                                <div className="contact-field p-relative c-message mb-45">
                                    <textarea name="message" id="message" cols="10" rows="10" placeholder="Write comments" onChange={this.handleChange} name="message" value={this.state.message}></textarea>
                                    {this.validator.message('message', this.state.message, 'required')}
                                </div>
                                {!this.state.lockSubmit && <button type="submit" name="" onClick={this.submitForm} className="themeBtn" >
								Submit
																</button>}
								{this.state.lockSubmit && <button type="submit" name="" className="themeBtn" >
																	Processing...
																</button>}
                            </div>
                            </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="contact-map">
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3314.949251620508!2d151.0024228152088!3d-33.81362268067155!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6b12a31ee6a6f49d%3A0x74dec1618ca5528d!2s46%20George%20St%2C%20Parramatta%20NSW%202150%2C%20Australia!5e0!3m2!1sen!2sin!4v1627032754335!5m2!1sen!2sin" width="570" height="580" allowFullScreen="" loading="lazy"></iframe>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className="la-naati-app-store-link-section" id="la-naati-app-store-link-section">
            <div className="container">
                <div className="row la-naati-app-store-link-mrow la-naati-app-free-tial-applink-row text-center">
                    <div className="col-md-2"></div>
                    <div className="col-md-4">
                        <a className="btn btn-lg btn-naati-appslink" href="https://apps.apple.com/au/app/la-ccl/id1536541464" title="Install From App Store">
                            <i className="fa fa-apple"></i> Install From App Store
                        </a>
                    </div>
                    <div className="col-md-4">
                        <a className="btn btn-lg btn-naati-appslink" href="https://play.google.com/store/apps/details?id=com.languageacademy.naati&hl=en_AU&gl=US" title="Install from Google Play">
                            <i className="fa fa-play"></i> Install From Google Play
                        </a>
                    </div>
                    <div className="col-md-2"></div>
                </div>
            </div>
        </section>
    <Footer />
    </React.Fragment>);
    }
}

export default NaatiAppLp;
