/*global chrome*/
import React from "react";
import API, { base } from "../../api.js";
import Slider from "react-slick";
import User from "../../assets/images/user-circle.svg";
class VideoTuts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      homePage: false,
      list: [],
    };
  }

  componentDidMount() {
    API({
      method: "GET",
      url: "testimonial/list",
      data: "json",
    })
      .then((data) => {
        this.setState({ list: data.data.response.data });
      })
      .catch(() => {
        this.setState({ lockSubmit: false });
      });
  }
  render() {
    var settings = {
      dots: false,
      infinite: true,
      slidesToShow: 3,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 2,
            infinite: true,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 480,
          settings: {
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };
    return (
      <section className="tutorialVd m-0">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 text-center">
              <h2 className="title">
                {this.props.title ? this.props.title : "Student Testimonials"}
              </h2>
              <div className="videosCntnt">
                <Slider
                  {...settings}
                  classNameName="testiContent regular02 slider"
                >
                  {this.state.list.map((item) => {
                    return (
                      <div className="videoBlk">
                        <div className="review__vid" key={item.id}>
                          <p>{item.message}</p>
                          <div className="align-items-center d-flex">
                            <img
                              src={User}
                              className="border user-rev__thumb"
                            />
                            <span className="ms-2">
                              <b>- {item.name}</b>
                            </span>
                          </div>
                        </div>
                        <p></p>
                      </div>
                    );
                  })}
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default VideoTuts;
