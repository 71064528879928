import React from "react";

// reactstrap components
import "components/custom.css";
import API from "api.js";
import NotificationAlert from "react-notification-alert";
import RightCard from "components/Payments/RightSideCard";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import StripeElements from "components/Payments/StripeElements";
import ErrorPopup from "components/PopUps/ErrorPopup";
import SelectPaymentMethod from "components/Plans/SelectPaymentMethod";
import errImage from "../assets/images/1-05.png";
import ReactHtmlParser from 'react-html-parser';
import SidebarClose from "components/Sidebar/SidebarClose";
import { Button } from "reactstrap";

let stripe;

let publishableKey;
if (process.env.REACT_APP_ENV === "production") {
  publishableKey = process.env.REACT_APP_STRIPE_LIVE_APP_KEY;
} else {
  publishableKey = process.env.REACT_APP_STRIPE_TEST_APP_KEY;
}
const stripePromise = loadStripe(publishableKey);
class Subscription extends React.Component {
  state = {
    cvc: "",
    message: "",
    expYear: "",
    expMonth: "",
    cardNumber: "",
    formProcess: false,
    paymentStatus: false,
    plan: false,
    country: null,
    discount: false,
    prorationSubtotal: null,
    paymentError: {
      hasError: false,
      error: "",
    },
    paymentMethod: "CARD"
  };

  componentDidMount() {
    this.loadPlan();
    document
      .getElementsByTagName("body")[0]
      .classList.add("leftSidebarClosed", "package-payment-page");
  }

  componentWillUnmount() {
    document
      .getElementsByTagName("body")[0]
      .classList.remove("leftSidebarClosed", "package-payment-page");
  }

  /**
   * Load Selected Plan.
   */
  loadPlan = () => {
    const base_url = process.env.REACT_APP_URL_ALT + "/api/v2/";
    API({
      method: "GET",
      url: base_url + "plan-wl/" + this.props.match.params.id,
    })
      .then((data) => {
        const { data: plan, prorationSubtotal } = data.data;
        const discount = prorationSubtotal !== null;
        if (plan.new !== "YES") {
          // this.props.history.replace("/admin/dashboard");
          // return;
        }
        this.setState(
          { plan, country: data.data.country, discount, prorationSubtotal },
          () => {
            this.loadStripe();
          }
        );
      })
      .catch(() => {});
  };

  handleChange = (evt) => {
    this.setState({
      [evt.target.name]: evt.target.value,
    });
  };

  notify = (type, message) => {
    switch (type) {
      case 1:
        type = "success";
        break;
      case 2:
        type = "danger";
        break;
      default:
        break;
    }
    var options = {};
    options = {
      place: "tr",
      message: (
        <div>
          <div>{message}</div>
        </div>
      ),
      type: type,
      icon: "now-ui-icons ui-1_bell-53",
      autoDismiss: 7,
    };
    this.refs.notificationAlert.notificationAlert(options);
  };

  /**
   * Api call to purchase.
   */
  purchase = async (data) => {
    let bodyFormData = new FormData();
    bodyFormData.set("plan", this.props.match.params.id);
    bodyFormData.set("country", this.state.country);
    // bodyFormData.set("token", data.stripe_token);
    bodyFormData.set("token", data);

    if (
      this.state.discount &&
      this.state.prorationSubtotal &&
      this.state.prorationSubtotal.subtotal
    ) {
      bodyFormData.set("proration_time", this.state.prorationSubtotal.time);
      bodyFormData.set("proration_subtotal", this.state.prorationSubtotal.subtotal);
    }

    // API call
    let res = null;
    const url = process.env.REACT_APP_URL_ALT + "/api/v2/subscribe-wl";
    try {
      res = await API({
        method: "POST",
        url: url,
        data: bodyFormData,
      });
    } catch (error) {
      console.log(error);
    }

    // process if we get response from server.
    if (res) {
      // if success.
      if (res.status === 200) {
        if (res.data.actionRequired) {
          const { paymentIntent, error } = await stripe.confirmCardPayment(
            res.data.data.clientSecret
          );

          if (error) {
            this.setState({
              paymentError: { hasError: true, error: error.message },
            });
            // alert(error.message);
            if(res.data.data.update_invoice) {
              try {
                res = await API({
                  method: "POST",
                  url: "voidInvoice",
                  data: {"invoiceId": res.data.data.update_invoice},
                });
              } catch (error) {
                console.log(error);
              }
            }
          } else if (paymentIntent.status === "succeeded") {
            this.notify(1, res.data.message);
            this.setState({ paymentStatus: true });
          }
          // const res2 = await axios.get(`http://localhost:5000/check/${res.data.id}`);
          // alert(`Payment successful, payment ID - ${res.data.id}`);
        } else {
          if(res.data && res.data.data && res.data.data.status && res.data.data.status === "requires_payment_method") {
            this.notify(1, "Payment failed, please contact your card issuer, or use another payment method.");
            this.setState({
              formProcess: false,
            });
            return;
          }
          this.notify(1, res.data.message);
          this.setState({ paymentStatus: true });
        }
      }

      // if failed.
      if (!res.status) {
        this.notify(1, "Something went wrong");
      }

      // set State message.
      this.setState({
        message: ``,
        formProcess: false,
      });
    }
  };

  pay = async (e) => {
    e.preventDefault();
    if (
      !this.state.cardNumber ||
      !this.state.expMonth ||
      !this.state.expYear ||
      !this.state.cvv
    ) {
      this.setState({ message: "All fields are required" });
      return;
    }
    this.setState({
      formProcess: true,
      message: "",
    });

    // Create token and send token to api.
    window.stripeV2.createToken(
      {
        number: this.state.cardNumber,
        exp_month: this.state.expMonth,
        exp_year: this.state.expYear,
        cvc: this.state.cvc,
      },
      async (status, response) => {
        if (status === 200) {
          this.purchase({ stripe_token: response.id });
        } else {
          this.setState({
            message: response.error.message,
            formProcess: false,
          });
        }
      }
    );
  };

  loadStripe = () => {
    const { country } = this.state;
    let key;

    if (process.env.REACT_APP_ENV === "production") {
      key = process.env.REACT_APP_STRIPE_LIVE_APP_KEY;
    } else {
      key = process.env.REACT_APP_STRIPE_TEST_APP_KEY;
    }

    if (!window.document.getElementById("stripe-script")) {
      var s = window.document.createElement("script");
      s.id = "stripe-script";
      s.type = "text/javascript";
      s.src = "https://js.stripe.com/v3/";
      s.onload = () => {
        stripe = window.Stripe(key);
      };
      window.document.body.appendChild(s);
    }

  };


  /**
   * initiate UPI payment.
   */
  upiSelected = () => {
    this.props.history.push(`/admin/ccav/plans`);
  }


  /**
   * render stripe payment form.
   */
  stripeForm = () => {
    return (
      <div className="panel panel-default">
        {/*<h2 className="paymentFormTitle">Payments</h2>*/}
        <Elements
          children={
            <StripeElements
              stripe={stripePromise}
              purchase={this.purchase}
              message={this.state.message}
              formProcess={this.state.formProcess}
              planIsValid={this.state.plan.price === 0 ? false : true}
              setFormProcess={(status) =>
                this.setState({ formProcess: status })
              }
            />
          }
          stripe={stripePromise}
        />
        <p className="mt-2"></p>
      </div>
    );
  }

  selectedMethod = (method) => {
    this.setState({ paymentMethod: method });
  }

  makePayment = async () => {
    const data = {
      id: this.props.match.params.id
    }

    let response = await API({
      url: "createCheckoutSession",
      method: "POST",
      data
    });

    console.log(response);
    if(response.status !== 200) {
      console.log('failed');
      return;
    }

    window.location.href = response.data.checkout_url;
  }

  render() {
    if (this.state.paymentStatus) {
      // this.props.history.push(`/admin/sub/success?p=${this.state.plan.price}&currency=${ReactHtmlParser(this.state.plan.currency)}`);
    }

    return (
      <>
        <div className="content content-payment">
          <SidebarClose />
          <div className="">
            {this.state.paymentError.hasError && (
              <ErrorPopup
                country={this.state.country}
                upi={this.upiSelected}
                toggle={() => {
                  this.setState({
                    paymentError: { hasError: false, error: "" },
                  });
                }}
                message="Payment failed"
                subMessage={this.state.paymentError.error}
                img={<img src={errImage} style={{ width: "50%" }} alt="Error" />}
              />
            )}
            <NotificationAlert ref="notificationAlert" />
            <div className="row paymentFormOuter">
              <div className="col-xs-12 col-md-7 mr-auto pt-5 subPaymentPage paymentFormBlk">
                {this.state.country !== null && this.state.country !== "IN" && this.state.plan !== false ? (
                  <div className="panel panel-default">
                    <h2 className="paymentFormTitle">Payments</h2>
                    <Elements
                      children={
                        <StripeElements
                          stripe={stripePromise}
                          purchase={this.purchase}
                          message={this.state.message}
                          formProcess={this.state.formProcess}
                          planIsValid={this.state.plan.price === 0 ? false : true}
                          setFormProcess={(status) =>
                            this.setState({ formProcess: status })
                          }
                        />
                      }
                      stripe={stripePromise}
                    />
                    <p className="mt-2"></p>
                  </div>
                ) : (
                  <SelectPaymentMethod
                    stripeForm={this.stripeForm}
                    country={this.state.country}
                    isGold={this.state.plan.is_gold}
                    selectedMethod={this.selectedMethod}
                    plan={this.state.plan}
                  />
                )}
              </div>
              <Button className="btn btn-primary" onClick={this.makePayment}>buy now</Button>
              {this.state.plan && (
                <RightCard
                  plan={this.state.plan}
                  discount={this.state.discount}
                  prorationSubtotal={this.state.prorationSubtotal}
                  paymentMethod={this.state.paymentMethod}
                />
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Subscription;
