import React, { useEffect } from "react";
import { loadScript } from "util/loadScript";
import LaLogo from "../../../assets/images/la-logo.png";
import API from "api";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import ReactHtmlParser from "react-html-parser";

const RazorPay = ({ plan }) => {
  const { id: planId } = useParams();
  const history = useHistory();

  const userData = useSelector((state) => {
    const user = state.user;
    let data = { email: user.email };
    ifThere(["phone", "first_name"], data, user);
    return data;
  });


  function ifThere(keys, data, main) {
    keys.forEach((key) => {
      main[key] && (data[key] = main[key]);
    });
  }

  // console.log(userData);
  useEffect(() => {
    async function init() {
      // console.log("ran");
      const res = await loadScript(
        "https://checkout.razorpay.com/v1/checkout.js",
        "razorpay-script"
      );

      if (!res) {
        alert("Something went wrong. Please try again later");
        return;
      }
    }
    init();
  }, []);

  async function displayRazorpay() {
    try {
      const response = await API({
        method: "POST",
        url: "createSubscription",
        data: { package_id: planId },
      });

      if (response.status != 200) {
        return alert("Something went wrong. " + response.message);
      }

      const { subscriptionData } = response.data;
      const { id: subId } = subscriptionData;

      const options = {
        key: process.env.REACT_APP_RAZORPAY_PUBLIC_KEY,
        subscription_id: subId,
        name: "Language Academy",
        description: "Monthly Silver Plan",
        image: LaLogo,
        handler: afterPayment,
        prefill: userData,
        theme: {
          color: "#F37254",
        },
        config: {
          display: {
            hide: [
              {
                method: "card",
              },
            ],
            preferences: {
              show_default_blocks: true,
            },
          },
        },
      };
      const paymentObject = new window.Razorpay(options);
      paymentObject.open();
    } catch (error) {
      alert("Something went wrong. ", error.message);
    }
  }

  async function afterPayment({
    razorpay_payment_id,
    razorpay_subscription_id,
    razorpay_signature,
  }) {
    const data = {
      razorpay_payment_id,
      razorpay_signature,
      razorpay_subscription_id,
      plan_id: planId,
    };
    const resp = await API({
      method: "POST",
      url: "verifySignature",
      data,
    });

    // console.log(resp);
    if (resp.status == 200) {
      return history.push(
        `/admin/sub/success?p=${plan.price}&currency=${ReactHtmlParser(
          plan.currency
        )}&mode=razorpay`
      );
    }

    alert(
      "Something went wrong. Invalid payment signature. Please contact us regarding this issue"
    );
  }

  return (
    <button
      className="btn btn-info btn-block planBtn button-payment__razorpay"
      onClick={displayRazorpay}
    >
      BUY NOW
    </button>
  );
};

export default RazorPay;
