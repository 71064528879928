import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import { Puff } from  'react-loader-spinner'
import cookie from "react-cookies";

import "assets/css/designer.css";

import API, { base } from "api.js";
import EstimatedScore from "components/Dashboard/EstimatedScore.jsx";
import MyStats from "components/Dashboard/MyStats.jsx";
import PracticeDetails from "components/Dashboard/PracticeDetails.jsx";
import TuteVideos from "components/Dashboard/TuteVideos.jsx";
import RightSidebar from "components/Dashboard/RightSidebar.jsx";
import BookFreeClass from "components/Dashboard/Popup/BookFreeClass";
import TipVideo from "components/PopUps/TipVideo";
import SelectBranch from "components/Dashboard/Popup/SelectBranch";
import { useDispatch } from "react-redux";
import { setUserType } from "action";
import NotificationAlert from "components/Dashboard/NotificationAlert";
import SidebarClose from "components/Sidebar/SidebarClose";
import Modal from "components/PopUps/Modal";
import TimezoneForm from "components/Dashboard/TimezoneForm";

export default function Dashboard(props) {
	const [loading, setLoading] = useState(true);
	const [showLogin, setShowLogin] = useState(false);
	const [estimatedScore, setEstimatedScore] = useState(null);
	const [practiceData, setPracticeData] = useState(null);
	const [showAvgScore, setShowAvgScore] = useState(0);
	const [mockData, setMockData] = useState(null);
	const [videos, setVideos] = useState(null);
	const [classes, setClasses] = useState([]);
	const [subscription, setSubscription] = useState([]);
	const [userData, setUserData] = useState({});
	const [isSubscribed, setIsSubscribed] = useState(false);
	const [showDiscountPopup, setShowDiscountPopup] = useState(false);
	const [popupVideo, setPopUpVideo] = useState(null);
	const [selectBranchPopup, setSelectBranchPopup] = useState(false);
	const [branches, setBranches] = useState([]);
	const [isTimezonePopup, setIsTimezonePopup] = useState(false);
	const showVideoPopUp = popupVideo ? true : false;
	const dispatch = useDispatch();
	// const [error, setError] = useState(null);

	// useEffect(() => {
	// 	if (cookie.load('access-token') === undefined) {
	// 		setShowLogin(true);
	// 	}
	// }, []);


	/**
	 * ComponentDidMount.
	 */
	useEffect(() => {
		// add this class only for dashboard page.
		document.body.classList.add('dashboardPage');

		// load dashboard data.
		API({
			method: 'GET',
			url: 'dashboard_data'
		}).then(res => {
			const { data, user, isClassified, branches, userType } = res.data;

			const { 
				show_average_score, 
				estimated_score, 
				practice_data, 
				mock_data, 
				videos, 
				classes, 
				subscription,
				popup_video
			} = data;
			
			setShowAvgScore(show_average_score);
			setEstimatedScore(estimated_score);
			setPracticeData(practice_data);
			setMockData(mock_data);
			setVideos(videos);
			setClasses(classes);
			setSubscription(subscription);
			setUserData(user);
			setPopUpVideo(popup_video);
			setSelectBranchPopup(!isClassified);
			if(!isClassified) {
				setBranches(branches);
			}
			
			setTimeout(() => {
				setLoading(false);
			}, 300);

			let type;
			if(!userType || userType.student_role_id === 1) {
				type = 'free';
			} else if(userType.student_role_id === 2) {
				type = 'portal';
			} else if(userType.student_role_id === 3) {
				type = 'campus';
			} else if(userType.student_role_id === 4) {
				type = 'direct';
			}

			dispatch(setUserType(type));

			const isTimezoneSaved = !user.user_timezone;
			setIsTimezonePopup(isTimezoneSaved);

		}).catch(err => console.log(err));


		// dashboardPage class will be only on dashboard page.
		return (() => {
			document.body.classList.remove('dashboardPage');
		});
	}, []);

	useEffect(() => {
		if (userData !== undefined && userData !== null) {
			if(Object.values(userData).length > 0) {
				const { anysub, popup_query, active_subscription, type: userType } = userData;
				const isSubscribed = (active_subscription && active_subscription.length > 0) ? true : false;

				let showDiscountPopup = false;
				if(userType === 1 || userType === 2){
					if(anysub !== 1){
						if(popup_query !== 1){
							showDiscountPopup = true;
						}
					}
				}

				setShowDiscountPopup(showDiscountPopup);
				setIsSubscribed(isSubscribed);
			}
		}
	}, [userData])

	return (
		<>
			{!loading &&
				<>
					<div className="siteContent py-2">
						<SidebarClose />
						<NotificationAlert />
						<BookFreeClass isSubscribed={isSubscribed} showDiscountPopup={showDiscountPopup} />
						{selectBranchPopup && <SelectBranch branches={branches} togglePopup={setSelectBranchPopup} isOpen={selectBranchPopup} />}
						{showVideoPopUp && 
							<TipVideo showVideoPopUp={showVideoPopUp} video={popupVideo} setPopUpVideo={setPopUpVideo} /> 
						}
						<Modal 
							status={isTimezonePopup} 
							toggle={() => setIsTimezonePopup(false)} 
							showCloseBtn={false}
							render={(toggle) => <TimezoneForm toggle={toggle} />}
						/>
						<div className="card p-4 cardBox">
							<div className="row">
								<EstimatedScore estimatedScore={estimatedScore} />
								<MyStats practiceData={practiceData} mockData={mockData} />
							</div>
						</div>

						<div className="cardBlock py-3">
							<PracticeDetails show_average_score={showAvgScore} practiceData={practiceData} />
						</div>

						<div className="pteTutorial py-3">
							<TuteVideos videos={videos} />
						</div>
					</div>
					<RightSidebar classes={classes} subscription={subscription} />
				</>
			}
		</>
	);
}
