import React from "react";
import BootstrapModal from "components/PopUps/BootstrapModal";
import ModalSkeleton from "components/PopUps/ModalSkeleton";
import { useHistory } from "react-router-dom";

const ExplanationVidPopUp = ({ status, toggle, expVid }) => {
  const navigate = useHistory();
  const closeVideoModel = () => {
    toggle(false);
  };

  const video = expVid ? (
    <iframe
      src={expVid}
      width="90%"
      height="100%"
      style={{ border: "none" }}
      title="E1xplanation video"
    />
  ) : (
    <></>
  );

  let modal;
  if (expVid) {
    modal = (
      <BootstrapModal
        status={status}
        toggle={closeVideoModel}
        handleClick={closeVideoModel}
        btnText="Close"
        message=""
        data={video}
        width={{ width: "61%" }}
      />
    );
  } else {
    modal = (
      <ModalSkeleton
        status={status}
        toggle={closeVideoModel}
        handleClick={() => {navigate.push("/admin/sub/payment/form/37")}}
        btnText="Buy Plan"
        message="Only available for our premium users. Please upgrade to the Gold plan to access the videos."
        isSmall={false}
      />
    );
  }

  return <>{modal}</>;
};

export default ExplanationVidPopUp;
