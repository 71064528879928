import React from "react";
import "components/custom.css";
import API from "api.js";
import { store, persistor } from "store/index";
import { setSub } from "action/index.js";
import { Link } from "react-router-dom";
import ReactGA from "react-ga";
import ReactPixel from "react-facebook-pixel";

class SubSuccess extends React.Component {
  constructor(props) {
    super(props);
    const urlParams = new URLSearchParams(window.location.search);
    this.state = {
      interval: null,
      mode: urlParams.get("mode"),
    };
  }

  componentDidMount() {
    const urlParams = new URLSearchParams(window.location.search);
    const p = urlParams.get("p");
    const currency = urlParams.get("currency");
    const mode = urlParams.get("mode");

    ReactGA.initialize("UA-157984080-1");
    ReactGA.pageview(window.location.pathname + window.location.search);
    ReactGA.ga("event", "conversion", {
      send_to: "AW-796233751/328VCNOGi5QZEJeg1vsC",
      value: p,
      currency: currency,
      transaction_id: "",
    });

    ReactGA.ga("event", "conversion", {
      send_to: "AW-796233751/P6KqCK667sgZEJeg1vsC",
      value: p,
      currency: currency,
      transaction_id: "",
    });

    ReactPixel.init("2474090032742391");
    ReactPixel.init("197333187452630");
    ReactPixel.track("Purchase", { value: p, currency: "USD" });

    //var inter = setInterval(this.checkSub, 3000);
    //this.setState({interval:inter});
    var element = document.getElementsByClassName("wrapper-full-page");
    if (element.length > 0) {
      element[0].classList.add("full-height");
      element[0].classList.remove("wrapper-full-page");
    }
    document
      .getElementsByTagName("body")[0]
      .classList.add("leftSidebarClosed", "package-payment-page");
  }

  componentWillUnmount() {
    document
      .getElementsByTagName("body")[0]
      .classList.remove("leftSidebarClosed", "package-payment-page");
  }

  checkSub = async () => {
    let res = null;
    try {
      res = await API({
        method: "GET",
        url: "check/payment",
      });
    } catch (error) {}
    if (res.status === 200) {
      clearInterval(this.state.interval);
      store.dispatch(setSub(true));
    }
  };

  render() {
    return (
      <div className="col-xs-12 successContent">
        <img
          src="/images/subscription-success.png"
          alt="subscription-success"
        />
        <h2>Subscription Successful!</h2>
        <p className="fs-5">
          You will shortly receive an email with login link. If you already have
          an active account, you can login directly from{" "}
          <Link to="/auth/login">Here</Link>
        </p>
        <Link to={"/"}>Go to Homepage</Link>
      </div>
    );
  }
}

export default SubSuccess;
