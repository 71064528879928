import React, { useEffect } from "react";
import Header from "components/Frontend/Header";
import Footer from "components/Frontend/Footer";
import { Helmet } from "react-helmet";

const Inst = () => {

	return (
		<>
			<Helmet>
				<title>Language Academy | New Page</title>
			</Helmet>
			
			<Header />

			<div className="innerPage instPage">
		        <section className="bannerOuter">
		        	<div className="container">
		            	<div className="row">
		                	<div className="col-sm-12 text-center">
		                    	<h1>Revolutionise your institutes</h1>
		                    	<span>lorem Ipsum dummy text here.</span><br />
		                    	<a href="javascript:;" className="downArrow"><img src="images/banner-arrow.png" alt="banner-arrow" /></a>
		                	</div>
		            	</div>
		        	</div>
		        </section>
		        <section className="whylanAcdmy">
			        <div className="container">
			            <div className="row">
			                <div className="col-sm-12 text-center">
			                    <p class="font-medium">Revolutionise your institute's PTE preparation offerings with our cutting-edge PTE Practice & Mock Test software solution. Elevate your business with a personalised version of the Alfa PTE practice test software, tailor-made to suit your institute's needs. Here are the key features of our comprehensive institute software:</p>
			                </div>
			            </div>
			        </div>
			    </section>
		        <section className="">
			        <div className="container">
			            <div className="row">
			                <div className="col-sm-6 text-center">
			                	<img src="images/speaking-score.png" className="img-responsive"/>
			                </div>
			                <div className="col-sm-6">
			                    <h3>Why you should choose US</h3>
			                    <ul class="whyChooseList m-0 p-0">
			                    	<li class="mb-3"><i class="fa fa-check-circle"></i> <b>Branded Experience</b> - Customise the PTE practice software with your institute's logo, business name, and a unique personalised URL.</li>
			                    	<li class="mb-3"><i class="fa fa-check-circle"></i> <b>Student Management</b> - Enjoy complete control by creating and managing an unlimited number of student logins through a user-friendly admin panel.</li>
			                    	<li class="mb-3"><i class="fa fa-check-circle"></i> <b>Premium Credits</b> - No need to worry about credits expiring; you'll have continuous access to premium features.</li>
			                    	<li class="mb-3"><i class="fa fa-check-circle"></i> <b>Comprehensive Mock Tests</b> - Access a range of full-length mock tests as well as sectional mock tests, providing students with a holistic assessment of their skills.</li>
			                    	<li class="mb-3"><i class="fa fa-check-circle"></i> <b>Customization Options</b> - Add your institution's templates, grammar rules, prediction files, and strategy videos to the software for a tailored learning experience.</li>
			                    	<li class="mb-3"><i class="fa fa-check-circle"></i> <b>AI-Powered Scoring</b> - Benefit from the same advanced AI scoring system used in Alfa PTE for accurate and reliable evaluations.</li>
			                    	<li class="mb-3"><i class="fa fa-check-circle"></i> <b>Dedicated Support</b> - Our dedicated customer support team is here to assist your institute's needs promptly.</li>
			                    </ul>
			                </div>
			            </div>
			        </div>
			    </section>
		        <section className="py-5 my-5">
			        <div className="container">
			            <div className="row">
			                <div className="col-sm-6">
			                    <h3>Key Features in Detail</h3>
			                    <ul class="whyChooseList m-0 p-0">
			                    	<li class="mb-3"><i class="fa fa-check-circle"></i> <b>AI Clicks</b> - Unlimited AI Clicks for precise scoring in all Speaking and Writing questions.</li>
			                    	<li class="mb-3"><i class="fa fa-check-circle"></i> <b>Score Checks</b> - Unlimited Score Checks to gain insight into how PTE marks are allocated across different modules and question types.</li>
			                    	<li class="mb-3"><i class="fa fa-check-circle"></i> <b>Full Mock Tests</b> - Enjoy unlimited access to simulated Scored Mock Tests, closely mirroring real exam conditions with 95% accuracy.</li>
			                    	<li class="mb-3"><i class="fa fa-check-circle"></i> <b>Sectional Mock Tests</b> - Our unique module-wise performance analysis provides students with a clear picture of their strengths and weaknesses.</li>
			                    	<li class="mb-3"><i class="fa fa-check-circle"></i> <b>Custom Mock Tests</b> - Tailor mock tests to your preferences, selecting specific questions and patterns.</li>
			                    	<li class="mb-3"><i class="fa fa-check-circle"></i> <b>Latest Exam Questions</b> - Stay up-to-date with access to the largest pool of daily updated questions.</li>
			                    	<li class="mb-3"><i class="fa fa-check-circle"></i> <b>Latest Prediction Files</b> - Benefit from two monthly prediction file updates.</li>
			                    	<li class="mb-3"><i class="fa fa-check-circle"></i> <b>Templates</b> - Use our proven Essay and Summarise Spoken Text writing templates.</li>
			                    	<li class="mb-3"><i class="fa fa-check-circle"></i> <b>Strategy Videos</b> - Multilingual videos offer comprehensive guidance on strategies, template usage, and tips for various question types.</li>
			                    	<li class="mb-3"><i class="fa fa-check-circle"></i> <b>Targeted Study Plan</b> - Provide students with personalised study plans designed to help them achieve their desired scores.</li>
			                    </ul>
			                </div>
			                <div className="col-sm-6 text-center">
			                	<img src="images/speaking-score.png" className="img-responsive"/>
			                </div>
			            </div>
			            <div class="row mt-5">
			            	<div class="col">
			            		<p class="text-center font-medium m-0">Unlock all these incredible features and transform your institute's PTE preparation offerings.</p>
			            		<p class="text-center font-medium m-0">Your personalised business software is just one click away !</p>
			            	</div>
			            </div>
			        </div>
			    </section>
		    </div>

			<Footer />
		</>
	);
}
export default Inst;